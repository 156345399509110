<div class="content">
  <div class="sidenav-body-content" *ngIf="isDataAvailable$ | async">
    <div class="content-header">
      <div class="content-header-input">
        <button
          class="action-button primary-button"
          mat-button
          #button
          disableRipple
          (click)="onJobAdd(false)"
          name="{{ gridActionsText.Create }}"
          *ngIf="!isRegularUser"
        >
          <mat-icon>add</mat-icon>
          <span>Add Job</span>
        </button>
        <mat-form-field appearance="fill" class="search-field w-150">
          <input
            matInput
            placeholder="Search"
            autocomplete="off"
            (keyup)="onSearch($event)"
          />
          <mat-icon>search</mat-icon>
        </mat-form-field>
        <ng-select
          class="w-140"
          panelClass="myPanelClass"
          [disableOptionCentering]="true"
          #selectedFilter
          (focus)="selectedFilter.open()"
          (valueChange)="selectedFilter.close()"
          [(ngModel)]="userId"
          (change)="onUserSelectionChange($event)"
          *ngIf="!isRegularUser"
        >
          <ng-option *ngFor="let type of jobFilterList" [value]="type.value">
            {{ type.name }}
          </ng-option>
        </ng-select>
        <ng-select
          class="w-110"
          panelClass="myPanelClass"
          [disableOptionCentering]="true"
          [(ngModel)]="typeFilter"
          #progress
          (focus)="progress.open()"
          (valueChange)="progress.close()"
          (change)="onStatusSelectionChange($event)"
        >
          <ng-option value="-1">All Status</ng-option>
          <ng-option value="0">Yet to Start</ng-option>
          <ng-option value="1">In Progress</ng-option>
          <ng-option value="2">Completed</ng-option>
        </ng-select>
        <ng-multiselect-dropdown
          class="custom-multiselect w-140 job-dropdown inner-mutliselect"
          [placeholder]="'All Tasks'"
          [settings]="dropdownSettings"
          [data]="taskList"
          [(ngModel)]="selectedTaskItems"
          [ngModelOptions]="{ standalone: true }"
          (onSelect)="onTaskSelect($event)"
          (onDeSelect)="onTaskDeSelect($event)"
          (onDeSelectAll)="onDeSelectAllTasks()"
          (onSelectAll)="onSelectAllTasks($event)"
        >
        </ng-multiselect-dropdown>
      </div>
      <div class="content-right-button">
        <div class="sidenav-content">
          <div class="column-wrapper">
            <button
              type="button"
              mat-button
              disableRipple
              class="column-toggle-icon eye-icon"
              (click)="toggleList()"
              matTooltip="Customise Columns"
            >
              <svg
                width="25"
                height="19"
                viewBox="0 0 25 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.0316 19.0004H1.76842C1.28211 19.0004 0.865643 18.8305 0.519033 18.4908C0.172422 18.1511 -0.000587972 17.7431 1.50121e-06 17.2671V5.13373C1.50121e-06 4.65706 0.173307 4.24886 0.519917 3.90913C0.866528 3.56939 1.2827 3.39981 1.76842 3.40039H15.0316C15.5179 3.40039 15.9344 3.57026 16.281 3.90999C16.6276 4.24973 16.8006 4.65764 16.8 5.13373V17.2671C16.8 17.7437 16.6267 18.1519 16.2801 18.4917C15.9335 18.8314 15.5173 19.001 15.0316 19.0004ZM1.76842 7.73372H15.0316V5.13373H1.76842V7.73372ZM4.42105 9.46706H1.76842V17.2671H4.42105V9.46706ZM12.3789 9.46706V17.2671H15.0316V9.46706H12.3789ZM10.6105 9.46706H6.18947V17.2671H10.6105V9.46706Z"
                  fill="#6661B8"
                />
                <path
                  d="M20.4714 7V7.1H20.5714H21.4286H21.5286V7V4.52857H24H24.1V4.42857V3.57143V3.47143H24H21.5286V1V0.9H21.4286H20.5714H20.4714V1V3.47143H18H17.9V3.57143V4.42857V4.52857H18H20.4714V7Z"
                  fill="#6661B8"
                  stroke="#6661B8"
                  stroke-width="0.2"
                />
              </svg>
            </button>
            <div class="column-selector" *ngIf="showColumn">
              <div class="column-list">
                <div class="column-container" *ngFor="let item of columnList">
                  <mat-checkbox
                    [(ngModel)]="item.isSelected"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="item.isDisabled"
                    value="{{ item.settingTypeId }}"
                    >{{ item.settingName }}</mat-checkbox
                  >
                </div>
              </div>
              <div class="column-button">
                <button
                  class="action-button primary-button"
                  type="button"
                  mat-button
                  disableRipple
                  (click)="onSaveDynamicColumns()"
                >
                  Save
                </button>

                <button
                  class="action-button secondary-button"
                  type="button"
                  mat-button
                  disableRipple
                  (click)="onCancelClick()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <ng-select
            class="w-120 month-filter"
            panelClass="myPanelClass"
            [disableOptionCentering]="true"
            (change)="onSelectionChange($event)"
            [(ngModel)]="filter"
            #dateFilter
            (focus)="dateFilter.open()"
            (valueChange)="dateFilter.close()"
          >
            <ng-option *ngFor="let filter of filterTypes" [value]="filter.id">
              {{ filter.name }}
            </ng-option>
          </ng-select>
          <form [formGroup]="calendarForm" *ngIf="showCalendar">
            <div class="d-flex align-center">
              <mat-form-field class="mr-0">
                <mat-date-range-input [rangePicker]="picker">
                  <input
                    matStartDate
                    matInput
                    [value]="startDate.value"
                    formControlName="start"
                    #start
                    readonly
                  />
                  <input
                    matEndDate
                    matInput
                    [value]="endDate.value"
                    formControlName="end"
                    #end
                    readonly
                    (dateChange)="dateRangeChange(start.value, end.value)"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matPrefix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <div class="mar-daterange-arrow">
                  <button (click)="lessWeek()" type="button" disableRipple>
                    <mat-icon>keyboard_arrow_left</mat-icon>
                  </button>
                  <button (click)="addWeek()" type="button" disableRipple>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </button>
                </div>
              </mat-form-field>
            </div>
          </form>
          <div class="toolbar">
            <button mat-button [matMenuTriggerFor]="menu" disableRipple>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu
              #menu="matMenu"
              class="action-menu more-menu"
              xPosition="before"
            >
              <button
                mat-menu-item
                #button
                disableRipple
                (click)="onJobAdd(true)"
              >
                <mat-icon>add</mat-icon>Add Bulk Job
              </button>
              <button
                mat-menu-item
                #button
                disableRipple
                (click)="export(exportType.Excel, false)"
              >
                <mat-icon>border_all</mat-icon>Export to Excel
              </button>
              <button
                mat-menu-item
                #button
                disableRipple
                (click)="export(exportType.CSV, false)"
              >
                <mat-icon fontSet="material-icons-outlined"
                  >description</mat-icon
                >Export to CSV
              </button>
              <button
                mat-menu-item
                #button
                disableRipple
                (click)="export(exportType.PDF, false)"
              >
                <mat-icon fontSet="material-icons-outlined"
                  >picture_as_pdf</mat-icon
                >Export to PDF
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="alert-message" *ngIf="isViewPermission$ | async">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.ViewOnlyAccessMessage }}
    </div>
    <div
      class="mat-table-wrapper job-list-table mt-1"
      [ngClass]="[
        (isViewPermission$ | async) ? 'mat-table-wrapper-alert' : '',
        totalSelectedRecords > 0 ? 'mat-table-wrapper-full' : '',
        showPaginator && (totalRecord$ | async)! >= 10
          ? 'mat-table-wrapper-pagination'
          : ''
      ]"
    >
      <table
        mat-table
        [dataSource]="jobList"
        aria-describedby="Job list table"
        multiTemplateDataRows="true"
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
      >
        <ng-container
          *ngFor="let column of displayedJobColumns; let colIndex = index"
          matColumnDef="{{ column.settingName }}"
          [sticky]="colIndex < 5 && !noDataFound"
        >
          <ng-container *ngIf="column.settingName !== 'Action'; else action">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              [id]="column.settingName"
              [ngClass]="[column.settingName === 'ID' ? 'hidden' : '']"
            >
              <span *ngIf="colIndex > 1">
                {{ column.settingName }}
              </span>
              <span
                *ngIf="column.settingName === 'Job ID'"
                class="d-flex align-items-center job-check gap-10"
              >
                <mat-checkbox
                  [(ngModel)]="isAllSelected"
                  (change)="selectAll($event)"
                  (click)="$event.stopPropagation()"
                ></mat-checkbox>
                <!-- <a href="javascript:void(0)" class="text-primary">
                  <mat-icon class="cursor-pointer">expand_more</mat-icon>
                </a> -->
                {{ column.settingName }}
              </span>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngClass]="[column.settingName === 'ID' ? 'hidden' : '']"
            >
              <div class="d-flex align-items-center gap-20">
                <span
                  *ngIf="column.settingName === 'Job ID'"
                  class="d-flex align-items-center job-check gap-10"
                >
                  <mat-checkbox
                    [(ngModel)]="element.isSelected"
                    (change)="onCheckBoxSelected(element, $event)"
                    (click)="$event.stopPropagation()"
                  ></mat-checkbox>
                  <!-- <a
                    href="javascript:void(0)"
                    *ngIf="!(element === expandedJobElement)"
                    class="text-primary"
                    (click)="expandCollapseJob(element)"
                  >
                    <mat-icon class="cursor-pointer">expand_more</mat-icon>
                  </a>
                  <a
                    href="javascript:void(0)"
                    *ngIf="element === expandedJobElement"
                    class="text-primary"
                    (click)="expandCollapseJob(element)"
                  >
                    <mat-icon class="cursor-pointer">expand_less</mat-icon>
                  </a> -->

                  <div
                    class="listview"
                    [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
                    (click)="
                      +element[colIndex].split('|')[0] ===
                      jobStatusModel.Completed
                        ? updateStatus(element, jobStatusModel.NotYetStarted)
                        : updateStatus(element, jobStatusModel.Completed);
                      $event.stopPropagation()
                    "
                  >
                    <img
                      [src]="
                        +element[colIndex].split('|')[0] ===
                        jobStatusModel.Completed
                          ? 'assets/images/check.svg'
                          : 'assets/images/uncheck.svg'
                      "
                      [alt]="
                        +element[colIndex].split('|')[0] ===
                        jobStatusModel.Completed
                          ? 'Check'
                          : 'Uncheck'
                      "
                      width="17px"
                      class="cursor-pointer"
                      [matTooltip]="
                        +element[colIndex].split('|')[0] ===
                        jobStatusModel.Completed
                          ? 'Mark as yet to start'
                          : 'Mark as completed'
                      "
                    />
                  </div>
                  <img
                    *ngIf="
                      +element[colIndex].split('|')[1] === feeType.TimeAndFee
                    "
                    src="assets/images/timeandfees.svg"
                    width="15px"
                    alt="Time and Fees"
                    matTooltip="Time and Fees"
                  />
                  <img
                    *ngIf="
                      +element[colIndex].split('|')[1] === feeType.FixedPrice
                    "
                    src="assets/images/fixedfees.svg"
                    width="18px"
                    alt="Fixed Price"
                    matTooltip="Fixed Price"
                  />
                  <img
                    *ngIf="
                      +element[colIndex].split('|')[1] === feeType.NonBillable
                    "
                    src="assets/images/nonbillable.svg"
                    width="15px"
                    alt="Non Billable"
                    matTooltip="Non Billable"
                  />
                  <a
                    class="hyperlink white-space-nowrap mr-5"
                    [title]="element[colIndex].split('|')[2]"
                    (click)="onJobEdit(element)"
                    >{{ element[colIndex].split("|")[2] }}
                  </a>
                </span>
                <span *ngIf="column.settingName === 'Fees Type'">
                  <span class="white-space-nowrap">
                    {{ element[colIndex] }}</span
                  >
                </span>
                <span
                  *ngIf="column.settingName === 'Task'"
                  class="d-flex align-items-center gap-5"
                >
                  <span class="onelineclamp" title="{{ element[colIndex] }}">
                    {{ element[colIndex] }}</span
                  >
                </span>
                <span
                  *ngIf="column.settingName === 'Status'"
                  class="d-flex align-items-center gap-5 white-space-nowrap"
                >
                  <span title="{{ element[colIndex] }}">
                    <span
                      *ngIf="
                        +element[colIndex] === jobStatusModel.NotYetStarted
                      "
                    >
                      Yet to Start</span
                    >
                    <span
                      *ngIf="+element[colIndex] === jobStatusModel.InProgress"
                    >
                      In Progress</span
                    >
                    <span
                      *ngIf="+element[colIndex] === jobStatusModel.Completed"
                    >
                      Completed</span
                    >
                  </span>
                </span>
                <span
                  *ngIf="column.settingName === 'Client'"
                  class="d-flex align-items-center gap-5"
                >
                  <span class="onelineclamp" title="{{ element[colIndex] }}">
                    {{ element[colIndex] }}</span
                  >
                </span>

                <span
                  *ngIf="column.settingName === 'User'"
                  class="d-flex align-items-center gap-5 white-space-nowrap"
                >
                  <div class="d-flex align-items-center white-space-nowrap">
                    <app-show-more-added-data
                      [jobElement]="element"
                      [colIndex]="colIndex"
                      [moduleId]="moduleId"
                      [headerText]="'User Details'"
                    >
                    </app-show-more-added-data>
                  </div>
                </span>
                <span
                  *ngIf="column.settingName === 'Logged Time'"
                  class="d-flex align-items-center gap-5 white-space-nowrap"
                >
                  <div class="d-flex align-items-center white-space-nowrap">
                    <a
                      class="hyperlink white-space-nowrap mr-5"
                      [title]="element[colIndex]"
                      (click)="sideNav.toggle(); onLoggedTimeClick(element)"
                      >{{ element[colIndex] }}
                    </a>
                  </div>
                </span>
                <span
                  *ngIf="column.settingName === 'Sub Task'"
                  class="d-flex align-items-center gap-5 white-space-nowrap"
                >
                  <div
                    class="d-flex align-items-center white-space-nowrap"
                    *ngIf="element[colIndex] !== '0/0'"
                  >
                    <app-show-more-added-data
                      [jobElement]="element"
                      [colIndex]="colIndex"
                      [moduleId]="moduleId"
                      [headerText]="'Sub Task Details'"
                    >
                    </app-show-more-added-data>
                  </div>
                  <span
                    class="onelineclamp"
                    *ngIf="element[colIndex] === '0/0'"
                    title="{{ element[colIndex] }}"
                  >
                    {{ element[colIndex] }}</span
                  >
                </span>
                <span
                  *ngIf="column.settingName === 'Fees'"
                  class="d-flex align-items-center gap-5"
                >
                  <span
                    *ngIf="+element[1].split('|')[1] === feeType.TimeAndFee"
                    class="white-space-nowrap"
                    title="{{ element[colIndex] + ' / hr' }}"
                  >
                    {{ element[colIndex] + " / hr" }}</span
                  >
                  <span
                    *ngIf="+element[1].split('|')[1] !== feeType.TimeAndFee"
                    class="white-space-nowrap"
                    title="{{ element[colIndex] }}"
                  >
                    {{ element[colIndex] }}</span
                  >
                </span>
                <span
                  *ngIf="column.settingName === 'Spent'"
                  class="d-flex white-space-nowrap align-items-center justify-space-between wp-100 gap-10"
                  >£ {{ element[colIndex] | number : "1.2-2" }}
                  <div class="w-50 grid-progress">
                    <mat-progress-bar
                      mode="determinate"
                      value="{{ element[colIndex] }}"
                    ></mat-progress-bar>
                  </div>
                </span>
                <span
                  *ngIf="
                    column.settingName === 'Start Date' ||
                    column.settingName === 'End Date' ||
                    column.settingName === 'Created On' ||
                    column.settingName === 'Completed On'
                  "
                  class="d-flex align-items-center gap-5 white-space-nowrap"
                  >{{ element[colIndex] | date : "dd-LLL-yyyy" }}
                </span>
                <span
                  *ngIf="
                    column.settingName === 'Remaining' ||
                    column.settingName === 'Cost'
                  "
                  class="d-flex align-items-center gap-5 white-space-nowrap"
                  >£ {{ element[colIndex] | number : "1.2-2" }}
                </span>
                <span
                  *ngIf="
                    column.settingName === 'Created By' ||
                    column.settingName === 'Completed By' ||
                    column.settingName === 'Recurring'
                  "
                  title="{{ element[colIndex] }}"
                  class="d-flex align-items-center gap-5"
                >
                  <span class="white-space-nowrap">
                    {{ element[colIndex] }}</span
                  >
                </span>
                <div
                  *ngIf="
                    colIndex > 1 &&
                    column.settingName !== 'Status' &&
                    column.settingName !== 'Client' &&
                    column.settingName !== 'Task' &&
                    column.settingName !== 'Sub Task' &&
                    column.settingName !== 'User' &&
                    column.settingName !== 'Logged Time' &&
                    column.settingName !== 'Fees' &&
                    column.settingName !== 'Start Date' &&
                    column.settingName !== 'End Date' &&
                    column.settingName !== 'Created By' &&
                    column.settingName !== 'Created On' &&
                    column.settingName !== 'Completed On' &&
                    column.settingName !== 'Completed By' &&
                    column.settingName !== 'Spent' &&
                    column.settingName !== 'Recurring' &&
                    column.settingName !== 'Remaining' &&
                    column.settingName !== 'Fees Type' &&
                    column.settingName !== 'Cost'
                  "
                >
                  {{ element[colIndex] }}
                </div>
              </div>
            </td>
          </ng-container>
          <ng-template #action>
            <th
              mat-header-cell
              *matHeaderCellDef
              [id]="column.settingName"
              class="overflow-inherit text-align-right"
            ></th>
            <td mat-cell *matCellDef="let element">
              <app-more-grid-actions
                [moduleId]="moduleId"
                [element]="element"
                [gridActions]="gridActions"
                (triggerActionType)="getActionType($event, element)"
              ></app-more-grid-actions>
            </td>
          </ng-template>
        </ng-container>

        <ng-container matColumnDef="expandedSubtaskDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="displayedJobColumns.length"
            class="flex-column inner-grid"
          >
            <div
              class="example-element-detail"
              [@detailExpand]="
                element == expandedJobElement ? 'expanded' : 'collapsed'
              "
            >
              <div class="wp-100">
                <table
                  aria-describedby="main-list one"
                  mat-table
                  [dataSource]="jobSubtaskList"
                  multiTemplateDataRows="true"
                >
                  <ng-container
                    *ngFor="
                      let column of displayedSubtaskColumns;
                      let colIndex = index
                    "
                    matColumnDef="{{ column.name }}"
                  >
                    <th mat-header-cell *matHeaderCellDef [id]="column.name">
                      <div *ngIf="colIndex === 0">
                        <a href="javascript:void(0)" class="text-primary">
                          <mat-icon class="cursor-pointer"
                            >expand_more</mat-icon
                          >
                        </a>
                      </div>
                      <span *ngIf="colIndex !== 0">{{ column.name }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="d-flex align-items-center gap-20">
                        <div *ngIf="colIndex === 0">
                          <a
                            href="javascript:void(0)"
                            *ngIf="!(element === expandedSubtaskElement)"
                            class="text-primary"
                            (click)="expandCollapseSubtask(element)"
                          >
                            <mat-icon class="cursor-pointer"
                              >expand_more</mat-icon
                            >
                          </a>
                          <a
                            href="javascript:void(0)"
                            *ngIf="element === expandedSubtaskElement"
                            class="text-primary"
                            (click)="expandCollapseSubtask(element)"
                          >
                            <mat-icon class="cursor-pointer"
                              >expand_less</mat-icon
                            >
                          </a>
                          <mat-icon
                            class="success cursor-pointer"
                            (click)="completeJobSubTask(element)"
                            >check_circle</mat-icon
                          >
                        </div>
                        <div *ngIf="colIndex > 0">
                          <span
                            *ngIf="
                              column.name === 'Amount' || column.name === 'Cost'
                            "
                            class="d-flex align-items-center gap-5 white-space-nowrap"
                            >£ {{ element[colIndex] | number : "1.2-2" }}
                          </span>
                          <span
                            *ngIf="
                              column.name !== 'Amount' && column.name !== 'Cost'
                            "
                            class="d-flex align-items-center gap-5"
                          >
                            {{ element[colIndex] }}
                          </span>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="expandedSubtaskUserDetail">
                    <td
                      mat-cell
                      *matCellDef="let element"
                      [attr.colspan]="displayedJobColumns.length"
                      class="flex-column"
                    >
                      <div
                        class="example-element-detail"
                        [@detailExpand]="
                          element === expandedSubtaskElement
                            ? 'expanded'
                            : 'collapsed'
                        "
                      >
                        <table
                          aria-describedby="main-list two"
                          mat-table
                          matSort
                          (matSortChange)="sorting(sort.active, sort.direction)"
                          [dataSource]="dataSource"
                        >
                          <ng-container
                            *ngFor="
                              let column of displayedSubtaskUserColumns;
                              let colIndex = index
                            "
                            matColumnDef="{{ column.name }}"
                          >
                            <th
                              mat-header-cell
                              *matHeaderCellDef
                              [id]="column.name"
                              fxFlex="50"
                              [ngClass]="[colIndex === 0 ? 'hidden' : '']"
                            >
                              <div>
                                <span>
                                  {{ column.name }}
                                </span>
                              </div>
                            </th>

                            <td
                              mat-cell
                              *matCellDef="let element; let rowIndex = index"
                            >
                              <div *ngIf="colIndex !== 0">
                                <div class="clamp">
                                  <span
                                    *ngIf="
                                      column.name === 'Amount' ||
                                      column.name === 'Cost'
                                    "
                                    class="d-flex align-items-center gap-5 white-space-nowrap"
                                    >£
                                    {{ element[colIndex] | number : "1.2-2" }}
                                  </span>
                                  <span
                                    *ngIf="
                                      column.name !== 'Amount' &&
                                      column.name !== 'Cost'
                                    "
                                    class="d-flex align-items-center gap-5"
                                  >
                                    {{ element[colIndex] }}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </ng-container>
                          <tr
                            mat-row
                            *matRowDef="
                              let row;
                              columns: displayedSubtaskUserColumnNames
                            "
                          ></tr>
                        </table>
                      </div>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedSubtaskColumnNames"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedSubtaskColumnNames"
                    matRipple
                    class="element-row"
                    [class.expanded]="row === expandedSubtaskElement"
                  ></tr>

                  <tr
                    mat-row
                    *matRowDef="
                      let row;
                      columns: displayedSubtaskColumnNames;
                      let i = index
                    "
                    [ngClass]="[isRowHighlighted ? 'highlightRow' : '']"
                  ></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td
                      class="mat-cell"
                      [ngClass]="'text-align-center'"
                      [attr.colspan]="displayedSubtaskColumnNames.length"
                    >
                      <span
                        *ngIf="
                          listParameters &&
                          !(
                            listParameters.search ||
                            listParameters.userId ||
                            listParameters.filter ||
                            listParameters.dateFilter
                          )
                        "
                        >{{ commonNotificationText.NoRecordsFound }}</span
                      >
                      <span
                        *ngIf="
                          listParameters &&
                          (listParameters.search ||
                            listParameters.userId ||
                            listParameters.filter ||
                            listParameters.dateFilter)
                        "
                        >{{
                          commonNotificationText.NoRecordsFoundForFilter
                        }}</span
                      >
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedJobColumnNames; sticky: true"
        ></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: displayedJobColumnNames; let i = index"
          [ngClass]="[isRowHighlighted ? 'highlightRow' : '']"
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            [ngClass]="'text-align-center'"
            [attr.colspan]="displayedJobColumnNames.length"
          >
            <span
              *ngIf="
                listParameters &&
                !(
                  listParameters.search ||
                  listParameters.userId ||
                  listParameters.filter ||
                  listParameters.dateFilter
                )
              "
              >{{ commonNotificationText.NoRecordsFound }}</span
            >
            <span
              *ngIf="
                listParameters &&
                (listParameters.search ||
                  listParameters.userId ||
                  listParameters.filter ||
                  listParameters.dateFilter)
              "
              >{{ commonNotificationText.NoRecordsFoundForFilter }}</span
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
  <app-dynamic-GIF
    *ngIf="!(isDataAvailable$ | async)"
    [moduleId]="moduleId"
    [moduleHeaderText]="moduleHeaderText"
  >
  </app-dynamic-GIF>
  <div
    *ngIf="totalSelectedRecords <= 0 && (totalRecord$ | async)! > 0"
    class="paginator-wrapper"
  >
    <app-custom-paginator
      *ngIf="(totalRecord$ | async)! >= 10"
      [length]="totalRecord$ | async"
      [listParameters]="listParameters"
      (pageChanged)="pageChanged($event)"
      (pageSizeVal)="pageSizeVal($event)"
      (togglePaginator)="togglePaginator($event)"
    >
    </app-custom-paginator>
  </div>
</div>

<app-card-footer
  *ngIf="totalSelectedRecords > 0"
  [getModuleId]="moduleId"
  [totalSelectedRecords]="totalSelectedRecords"
  [gridActions]="gridActions"
  (updateJobStatusClick)="updateJobStatusClick($event)"
  (cancelSelectionClick)="cancelSelectionClick()"
  (deleteClick)="onDeleteClick()"
  (exportClick)="export($event, false)"
  (printClick)="printClick()"
></app-card-footer>

<mat-drawer-container class="sidenav-container" autosize>
  <mat-drawer #sideNav class="sidenav-drawer" position="end">
    <div class="sidenav-body-content">
      <div
        class="hr-bottom d-flex justify-space-between align-items-center pb-5"
      >
        <h2 class="log-title">
          <img
            *ngIf="selectedJobDetails[1]?.split('|')[1] === feeType.TimeAndFee"
            src="assets/images/timeandfees.svg"
            width="15px"
            alt="Time and Fees"
          />
          <img
            *ngIf="selectedJobDetails[1]?.split('|')[1] === feeType.FixedPrice"
            src="assets/images/fixedfees.svg"
            width="18px"
            alt="Fixed Price"
          />
          <img
            *ngIf="selectedJobDetails[1]?.split('|')[1] === feeType.NonBillable"
            src="assets/images/nonbillable.svg"
            width="15px"
            alt="Non Billable"
          />
          {{ selectedJobDetails[1]?.split("|")[2] }}

          <mat-icon>north</mat-icon>
          {{ selectedJobDetails[4] }}

          <mat-icon>north</mat-icon>
          {{ selectedJobDetails[5] }}
        </h2>
        <button class="action-button" mat-button (click)="sideNav.close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="mat-dialog-wrapper pt-1">
        <app-job-details-user-roi
          [isFromJobList]="true"
          [triggerJobId]="triggerJobId"
          [triggerJobFeesType]="triggerJobFeesType"
        ></app-job-details-user-roi>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>
