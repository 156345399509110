import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  EntityTypes,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/enums';
import {
  ActivityTask,
  ActivityTaskParameters,
  GlobalComponent,
  MenuItem,
} from '@app/core/models';
import { CommonService, SettingService, TimeService } from '@app/core/services';
import {
  GetActivityList,
  GetClientLimit,
  GetFileAsByte,
  GetMenus,
  GetSettingsData,
  GetUserBusinessPermission,
  RetrieveSubscription,
  SettingsState,
  TimeState,
  UpdateThemeColor,
} from '@app/core/store';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { interval } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as theme from 'src/assets/json/themes.json';

declare const tinycolor: any;
// declare var pendo;

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  // Multicolor theme
  colorSelected = '#6d41a1';
  singletheme: any;
  themes = JSON.parse(JSON.stringify(theme));
  colors: string[] = [
    '#6d41a1',
    '#6661b8',
    '#4461d7',
    '#a0144f',
    '#045b62',
    '#14539a',
    '#a76f05',
    '#000000',
  ];

  primaryColor: string;
  primaryColorPalette: Color[] = [];
  commonService: CommonService;
  menuList: Array<MenuItem>;
  manageSubMenuList: Array<MenuItem>;
  settingSubMenuList: Array<MenuItem>;
  environment = environment;
  modules = Modules;
  businessLogo: string;
  businessName: string;
  userData: any;
  isShowActivity = false;
  isShowAnnouncement = false;
  isShowLive = false;
  activityList: any = [];
  message = '';
  appUrl = '';
  activityTaskParameters: ActivityTaskParameters;
  activities: Array<ActivityTask>;
  // End Intalization of Muticolor theme
  selectedModule = 0;
  hasAttachments = false;
  attachment: any[];
  entityTypes = EntityTypes;
  timeService: TimeService;
  router: Router;
  store: Store;
  datePipe: DatePipe;
  cookieService: CookieService;
  settingService: SettingService;
  subscriptionModuleList: any = [];
  profileUrl = '';
  userId = this.globalComponent.getLoggedInUserId();
  myAdminUrl = '';
  helpDeskUrl = environment.helpDeskUrl;
  subscriptionPortalAppUrl = '';
  isSubscriptionPortalEnabled: boolean = false;
  currentTime = new Date();
  isOwnerUser = this.globalComponent.isOwner();
  subscriptionData: any;
  clientLimit: any;
  constructor(
    public oidcSecurityService: OidcSecurityService,
    public globalComponent: GlobalComponent,
    private injector: Injector
  ) {
    this.commonService = this.injector.get<CommonService>(CommonService);
    this.timeService = this.injector.get<TimeService>(TimeService);
    this.store = this.injector.get<Store>(Store);
    this.router = this.injector.get<Router>(Router);
    this.datePipe = this.injector.get<DatePipe>(DatePipe);
    this.cookieService = this.injector.get<CookieService>(CookieService);
    this.settingService = this.injector.get<SettingService>(SettingService);
    this.onSelectionChange(this.globalComponent.themecolor(), false);
    this.userData = JSON.parse(
      sessionStorage.getItem(`${environment.clientId}_userData`) as any
    );
    this.userData.picture = `${environment.cdnUrl}` + this.userData.picture;

    interval(1000).subscribe((x) => {
      const timerRunning = this.store.selectSnapshot(TimeState.isTimerRunning);
      this.timeService.displayTimeTracker(timerRunning);
    });
    this.settingService.reloadMenus.subscribe((reload) => {
      if (reload) {
        this.getMenus();
      }
    });
  }

  isActive(val: string): string {
    const currentUrl = this.router.url.split('/')[1];
    const newValue = val?.split('/')[1];
    if (currentUrl === newValue) {
      return 'active';
    }
    return '';
  }

  isActiveWidget(val: Array<string>): boolean {
    return val.includes(this.router.url);
  }

  ngOnInit(): void {
    this.getBusinessDetails();
    this.getMenus();
    this.retrieveSubscription();
    this.getClientLimit();
    this.getUserBusinessPermission();

    this.commonService.triggerHeaderLogo$.subscribe((res) => {
      if (res) {
        this.getBusinessDetails();
      }
    });

    // setTimeout(() => {
    //   this.pendoTracking();
    // }, 3000);
  }

  onAnnoucementActivity(tabId): void {
    this.router.navigate(['/announcementAndActivity/' + tabId]);
  }

  onHeaderPlusMenuClick(subMenu?: any) {
    if (
      subMenu?.ordinal !== Modules.Timelog &&
      subMenu?.ordinal !== Modules.Job
    ) {
      this.router.navigate([subMenu.addNewUrl]);
    } else {
      this.router.navigate([subMenu.addNewUrl, { isAdd: true }]);
    }
  }

  onSubMenuClick(url: string): void {
    this.router.navigate([url]);
  }

  onDownloadAttachmemt(url): void {
    const params = {
      fileUrl: url,
    };

    this.commonService.downloadFile(params).subscribe();
  }

  getRecentActivity(): void {
    this.activityList = [];

    this.activityTaskParameters = {
      moduleId: 0,
      pageNumber: 1,
      pageSize: 5,
      search: '',
      entityId: Guid.EMPTY as unknown as Guid,
      startDate: '',
      endDate: '',
      dateFilter: 1,
    };

    this.store
      .dispatch(new GetActivityList(this.activityTaskParameters))
      .pipe(
        tap((res) => {
          this.createMessage(res.activity.activities[0].activityModels);
        })
      )
      .subscribe();
  }

  createMessage(val: any): void {
    this.activityList = [];
    this.activities = val;
    if (this.activities) {
      this.activities.forEach((items) => {
        this.message = ' ';
        const moduleName = this.getEmunKeyByValue(items.moduleId, Modules);
        const emailId = items.entities[0].toEmail
          ?.map((x) => x)
          .join(',')
          .slice(0, -1);

        const name = items.entities?.map((x) => x.name).join(',');
        if (this.selectedModule === Modules.Time) {
          this.createTimeMessage(items, moduleName, name);
        } else if (this.selectedModule === Modules.Expenses) {
          this.createExpenseMessage(items, name);
        } else if (this.selectedModule === Modules.Jobs) {
          this.createJobMessage(items, name);
        } else {
          switch (items.entityTypeId) {
            case EntityTypes.ExportedPDF:
              this.message += 'Exported the ';
              this.message += moduleName + ' on PDF';
              break;
            case EntityTypes.ExportedEXCEL:
              this.message += 'Exported the ';
              this.message += moduleName + ' on Excel';
              break;
            case EntityTypes.ExportedCSV:
              this.message += 'Exported the ';
              this.message += moduleName + ' on CSV';
              break;
            case EntityTypes.NoteCreated:
              this.message +=
                'added a new notes as' +
                ' <strong>"' +
                items.entities[0].note +
                '"</strong> on ' +
                moduleName +
                ' ';
              break;
            case EntityTypes.Recorded:
              this.message +=
                ' Recorded payment invoice <strong>' +
                items.entities[0].name +
                '</strong> for <strong>' +
                items.entities[0].amount +
                '</strong> GBP via <strong>' +
                items.entities[0].paymentMethodName +
                '</strong>';
              break;
            case EntityTypes.RecieptSent:
              this.message +=
                ' Sent receipt to <strong>" ' +
                emailId +
                '"</strong> for the payment of ' +
                items.entities[0].amount +
                'GBP on the' +
                moduleName +
                ' ';
              break;
            case EntityTypes.Sent:
              this.message +=
                ' Sent Invoice <a class="link fw-bold">' +
                items.entities[0].name +
                '</a> to ' +
                emailId +
                ' via <strong>' +
                items.entities[0].providerType +
                '</strong>';
              break;
            case EntityTypes.DeleteAll:
              this.message +=
                ' Deleted all the payments and reminders that are made on the ' +
                moduleName +
                ' ';
              break;
            case EntityTypes.Status:
              // Arena updated invoice INV124 status from Draft to Sent
              this.message +=
                ' Updated ' +
                moduleName +
                '  <strong>' +
                name +
                '</strong> status from <strong>' +
                items.entities[0].previousStatus +
                '</strong>  to  <strong>' +
                items.entities[0].newStatus +
                '</strong>';
              break;
            case EntityTypes.Recurring:
              this.message +=
                'Created a recurring ' +
                moduleName +
                ' with schedule ' +
                items.entities[0].customMessage +
                ' ';
              break;
            case EntityTypes.Deleted:
              this.message +=
                this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
                ' ' +
                this.getSingularModuleName(moduleName) +
                ' ';
              break;
            case EntityTypes.Reminder:
              this.message +=
                ' Sent Reminder invoice <strong>' +
                items.entities[0].name +
                '</strong> for the amount due <strong>' +
                items.entities[0].amount +
                ' GBP</strong> to ' +
                emailId;
              break;

            case EntityTypes.DeletePayment:
              this.message +=
                'Deleted the payment from invoice <strong>' +
                items.entities[0].name +
                '</strong> for the <strong>' +
                items.entities[0].amount +
                '</strong> GBP paid via <strong>' +
                items.entities[0].paymentMethodName +
                '</strong>';
              break;

            case EntityTypes.UpdatedBasicInfo:
              this.message +=
                'Updated ' +
                moduleName +
                ' <strong>' +
                items.entities[0].name +
                '</strong> basic info';
              break;

            case EntityTypes.UpdatedReminders:
              this.message +=
                'Updated ' +
                moduleName +
                ' <strong>' +
                items.entities[0].name +
                '</strong> reminders';
              break;

            default:
              this.message +=
                this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
                ' ' +
                moduleName +
                ' ';
              break;
          }
        }

        items.entities.forEach((entity) => {
          this.attachment = entity.attachments;
          this.hasAttachments = entity.hasAttachment ?? false;
        });

        const obj = {
          id: items.entities[0].id,
          createdByName: items.createdByName,
          name: items.entities[0].name,
          message: this.message,
          entityTypeId: items.entityTypeId,
          activityDate: items.activityDate,
          hasAttachment: this.hasAttachments,
          attachments: this.attachment,
        };
        this.activityList.push(obj);
      });
    }
  }

  getSingularModuleName(moduleName) {
    return moduleName.substring(
      0,
      moduleName.toLowerCase() === ModuleName.Taxes
        ? moduleName.length - 2
        : moduleName.length - 1
    );
  }

  getEmunKeyByValue(value: number, enumName: any): string {
    const indexOfS = Object.values(enumName).indexOf(
      value as unknown as InstanceType<typeof enumName>
    );
    let key = Object.keys(enumName)[indexOfS];
    key = key.replace(/([A-Z])/g, ' $1').trim();

    return key;
  }

  createTimeMessage(items: any, moduleName: string, name: string): void {
    if (items.entityTypeId === EntityTypes.Submitted) {
      // John Submitted timesheet for the week 24-Jun-2022 to 31-Jun-2022 for approval
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' timesheet for the week <strong>' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong> for approval';
    }
    if (items.entityTypeId === EntityTypes.Resubmitted) {
      // John Resubmitted timesheet for the week 24-Jun-2022 to 31-Jun-2022 for  approval
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' timesheet for the week  <strong>' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong> for approval';
    }
    if (items.entityTypeId === EntityTypes.Reminder) {
      // Arena Sent reminder to John, Smith, Bob for submiting the timesheet for the week 24-Jun-2022 to 31-Jun-2022
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' reminder to <strong>' +
        name +
        '</strong> for submiting the timesheet for the week <strong>' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Sent) {
      // Arena Sent email to John, Smith, Bob with notes 'Sent notes will be here'  for the week 24-Jun-2022 to 31-Jun-2022
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        'Sent email to <strong>' +
        name +
        ' </strong> with notes Sent notes will be here for the week ' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to <strong>' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Approved) {
      // Arena Approved timesheets of John, Smith, Bob for the week 24-Jun-2022 to 31-Jun-2022
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' timesheets of <strong>' +
        name +
        '</strong> for the week <strong>' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Withdrawn) {
      // Arena withdrawn approval on the timesheet submitted by John, smith for the week 24-Jun-2022 to 31-Jun-2022
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' approval on the timesheet <strong>' +
        name +
        ' </strong> for the week <strong>' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
  }

  createExpenseMessage(items: any, name: string): void {
    if (items.entityTypeId === EntityTypes.Created) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' an expense for the amount of <strong>' +
        items.entities[0].amount +
        ' GBP </strong> to the <strong>' +
        this.datePipe.transform(items.expenseDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Submitted) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' expenses for the amount of <strong>' +
        items.entities[0].amount +
        ' GBP </strong>for the week <strong> ' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Approved) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        '  expenses submitted by <strong>' +
        name +
        '</strong> for the amount of <strong>' +
        items.entities[0].amount +
        ' GBP </strong>for the week <strong> ' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Sent) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        " a note '" +
        items.entities[0].note +
        "' on email to <strong>" +
        name +
        ' </strong> on expense submitted for the amount of <strong> ' +
        items.entities[0].amount +
        ' GBP </strong> for the week <strong>' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Withdrawn) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' approval on the expenses submitted by <strong>' +
        name +
        '</strong> for the amount of <strong> ' +
        items.entities[0].amount +
        ' GBP </strong> for the week <strong>' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Reminder) {
      this.message +=
        'Sent ' +
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' expense to <strong>' +
        name +
        '</strong> for the amount due <strong>' +
        items.entities[0].amount +
        ' GBP</strong>';
    }
  }

  createJobMessage(items: any, name: string): void {
    if (items.entityTypeId === EntityTypes.Created) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        '  <strong>' +
        items.entities[0].code +
        ' </strong>';
    }
    if (items.entityTypeId === EntityTypes.Updated) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' <strong>' +
        items.entities[0].code +
        ' </strong>';
    }
    if (items.entityTypeId === EntityTypes.Comment) {
      this.message +=
        'added comment' +
        ' <strong>"' +
        items.entities[0].note +
        '"</strong> on <strong>' +
        items.entities[0].code +
        ' </strong>';
    }
  }

  onProfileSubMenuClick(url: string): void {
    if (url?.includes('/announcementAndActivity')) {
      const redirectUrl = url.split('/')[1];
      const id = url.split('/').pop();
      this.router.navigate([redirectUrl + '/' + id]);
    } else if (url) {
      this.router.navigate([url]);
    } else {
      this.cookieService.deleteAll();
      this.oidcSecurityService.logoff();
    }
  }

  onSelectionChange(event: any, isThemeColor: boolean) {
    this.commonService.clearThemeMessages();
    this.colorSelected = event;
    this.primaryColor = event;

    this.computeColors(this.primaryColor);

    if (isThemeColor) {
      this.store.dispatch(new UpdateThemeColor(this.primaryColor)).subscribe();
    }
  }

  computeColors(hex: string): void {
    for (let index = 0; index < Object.keys(this.themes).length - 2; index++) {
      if (this.themes[index].id === hex) {
        const selectedThemeArray = this.themes[index];

        this.primaryColorPalette = [
          this.getColorObject(selectedThemeArray?.id, '500'),
          this.getColorObject(selectedThemeArray?.color50, '50'),
          this.getColorObject(selectedThemeArray?.color100, '100'),
          this.getColorObject(selectedThemeArray?.color200, '200'),
          this.getColorObject(selectedThemeArray?.color300, '300'),
        ];
      }
    }

    for (const color of this.primaryColorPalette) {
      const key = `--theme-primary-${color.name}`;
      const value = color.hex;
      document.documentElement.style.setProperty(key, value);
    }
  }

  getColorObject(value, name): Color {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight(),
    };
  }

  getBusinessDetails(): void {
    this.commonService.clearMessages();
    this.store
      .dispatch(new GetSettingsData())
      .pipe(
        tap(() => {
          const businessData = this.store.selectSnapshot(
            SettingsState.getSettingData
          );

          const isAdministrativeFieldVisible =
            businessData.isAdministrativeFieldVisible ?? false;
          this.cookieService.set(
            'isAdministrativeFieldVisible',
            isAdministrativeFieldVisible.toString()
          );

          if (businessData.logo) {
            this.store.dispatch(new GetFileAsByte(businessData.logo)).subscribe(
              (res) => {
                this.businessLogo = res.common.fileByteUrl;
              },
              (error) => {
                this.businessLogo = '';
              }
            );
          } else {
            this.businessLogo = '';
          }

          this.businessName = businessData.fullName ?? '';
        })
      )
      .subscribe();
  }

  getMenus(): void {
    this.store.dispatch(new GetMenus()).subscribe((x) => {
      this.menuList = x.setting.menus;

      this.menuList.forEach((element) => {
        if (element.ordinal === this.modules.Activity) {
          this.isShowActivity = true;
        }

        if (element.ordinal === this.modules.Announcement) {
          this.isShowAnnouncement = true;
        }

        if (element.ordinal === this.modules.Standard) {
          this.manageSubMenuList = element.children;
        }

        if (element.ordinal === this.modules.Setting) {
          this.settingSubMenuList = element.children;
        }

        if (element.ordinal === this.modules.Live) {
          this.isShowLive = true;
        }
      });
    });
  }

  logout(): void {
    this.cookieService.deleteAll();
    this.oidcSecurityService.logoff();
  }

  produdctSubscriptionClick(): void {
    if (this.isSubscriptionPortalEnabled)
      window.location.replace(this.subscriptionPortalAppUrl);
  }

  retrieveSubscription(): void {
    this.store.dispatch(new RetrieveSubscription()).subscribe(
      (res) => {
        if (res.subscription.retrieveSubscriptionList.isSuccess) {
          this.subscriptionData =
            res.subscription.retrieveSubscriptionList.data;
          this.subscriptionModuleList =
            res.subscription.retrieveSubscriptionList.data.listOfAddons ?? [];
          this.profileUrl =
            res.subscription.retrieveSubscriptionList.data.picture;
          this.isSubscriptionPortalEnabled =
            res.subscription.retrieveSubscriptionList.data.isSubscriptionPortalEnabled;
          this.subscriptionPortalAppUrl = this.isSubscriptionPortalEnabled
            ? res.subscription.retrieveSubscriptionList.data
                .subscriptionPortalAppUrl
            : '';
          this.subscriptionModuleList.forEach((element) => {
            if (element.externalName === 'My Admin') {
              this.myAdminUrl = element.appUrl;
            }
          });
        } else {
          this.subscriptionModuleList = [];
          this.profileUrl = '';
        }
      },
      (error) => {
        this.subscriptionModuleList = [];
      }
    );
  }

  redirectToModule(subscriptionModule, event: Event): void {
    if (subscriptionModule.isDisabled) {
      this.appUrl = '';
      event.preventDefault();
    } else {
      this.appUrl = subscriptionModule.appUrl;
    }
  }

  getUserBusinessPermission(): void {
    this.store
      .dispatch(new GetUserBusinessPermission(this.userId))
      .subscribe((res) => {
        if (res.setting.userPermission) {
          const userPermissions = this.store.selectSnapshot(
            SettingsState.getUserBusinessPermission
          );
          localStorage.setItem(
            'userPermission',
            JSON.stringify(userPermissions)
          );
        }
      });
  }

  // pendoTracking() {
  //   pendo.initialize({
  //     visitor: {
  //       id: this.userData.Email,
  //       LoginUserName: this.userData.Name,
  //       Email: this.userData.Email,
  //       UserType:
  //         this.userData.role === 'ClientOwner'
  //           ? 'Accountant'
  //           : this.userData.UserRole === 'ClientUser'
  //           ? 'Staff'
  //           : this.userData.UserRole === 'ClientUser'
  //           ? 'Client User'
  //           : '',
  //       IsSMEUser: this.userData.companyType === 'Accountants' ? 'No' : 'Yes',
  //       IsSuperAccountant: this.userData.role === 'ClientOwner' ? 'Yes' : 'No',
  //       SubscriptionType: this.subscriptionData.subscriptionType ?? '',
  //       CurrentModule:
  //         this.userData && this.userData.CurrentModules
  //           ? this.userData.CurrentModules
  //           : '',
  //       LastLogIn: this.currentTime,
  //       PermissionLevel:
  //         this.userData.role === 'ClientOwner'
  //           ? 'Accountant'
  //           : this.userData.UserRole === 'ClientUser'
  //           ? 'Staff'
  //           : this.userData.UserRole === 'ClientUser'
  //           ? 'Client User'
  //           : '',
  //       ClientNumber: this.clientLimit ?? '',
  //       SubscriptionAmount: this.subscriptionData.subscriptionAmount ?? '',
  //       SubscriptionExpireDate: this.subscriptionData.expiredDate,
  //     },
  //     account: {
  //       id: this.userData.SQLId,
  //       LoginUserName: this.userData.Name,
  //       Email: this.userData.Email,
  //       UserType:
  //         this.userData.role === 'ClientOwner'
  //           ? 'Accountant'
  //           : this.userData.UserRole === 'ClientUser'
  //           ? 'Staff'
  //           : this.userData.UserRole === 'ClientUser'
  //           ? 'Client User'
  //           : '',
  //       IsSMEUser: this.userData.companyType === 'Accountants' ? 'No' : 'Yes',
  //       IsSuperAccountant: this.userData.role === 'ClientOwner' ? 'Yes' : 'No',
  //       SubscriptionType: this.subscriptionData.subscriptionType ?? '', //subscription API
  //       CurrentModule:
  //         this.userData && this.userData.CurrentModules
  //           ? this.userData.CurrentModules
  //           : '',
  //       LastLogIn: this.currentTime,
  //       PermissionLevel:
  //         this.userData.role === 'ClientOwner'
  //           ? 'Accountant'
  //           : this.userData.UserRole === 'ClientUser'
  //           ? 'Staff'
  //           : this.userData.UserRole === 'ClientUser'
  //           ? 'Client User'
  //           : '',
  //       ClientNumber: this.clientLimit ?? '', //new API
  //       SubscriptionAmount: this.subscriptionData.subscriptionAmount ?? '', //subscription API
  //       SubscriptionExpireDate: this.subscriptionData.expiredDate, //subscription API
  //     },
  //   });
  // }

  myAdminClick(): void {
    window.location.replace(this.myAdminUrl);
  }

  redirectToHome(): void {
    window.location.replace(environment.stsServerUrl);
  }

  onHelpClick(): void {
    window.open(this.helpDeskUrl, '_blank');
  }

  getClientLimit(): void {
    this.store.dispatch(new GetClientLimit()).subscribe(
      (res) => {
        if (res.subscription.clientLimit) {
          this.clientLimit = res.subscription.clientLimit ?? 0;
        }
      },
      (error) => {
        this.commonService.onFailure(NotificationTextMessage.errorMessage);
      }
    );
  }
}
