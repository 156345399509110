import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CalendarType,
  JobStatusModel,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/enums';
import { CalendarDetailParameters, GlobalComponent } from '@app/core/models';
import { CommonService, NotificationService } from '@app/core/services';
import {
  GetCalendarDetail,
  GetDateWiseJobList,
  TimeState,
} from '@app/core/store';
import { JobState } from '@app/core/store/job';
import { Store } from '@ngxs/store';
import {
  EventRenderedArgs,
  EventSettingsModel,
  PopupOpenEventArgs,
  View,
} from '@syncfusion/ej2-angular-schedule';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { AddJobsComponent } from '../add-jobs/add-jobs.component';

@Component({
  selector: 'app-calendar-list',
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.scss'],
})
export class CalendarListComponent implements OnInit {
  istoggleSideList = true;
  isShowSideListAction = false;
  jobDate: any;
  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  calendarDetailParameters: CalendarDetailParameters;
  calendarType: CalendarType;
  calendarViewType: number = 1;
  userId: Guid = Guid.EMPTY as unknown as Guid;
  dateWiseJobList: any = [];
  isRegularUser = this.globalComponent.isRegularUser();
  moduleId = Modules.JobsCalendar;
  jobStatus = JobStatusModel;
  search = '';
  calendarActionType = '';
  calendarSideList: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public selectedDate: Date = new Date();
  public showWeekend: boolean = true;
  public currentView: View = 'Month';
  public eventSettings: EventSettingsModel = {
    dataSource: [],
  };

  isTimerRunning: boolean = false;
  runningTimerData: any;
  defaultGuidValue = Guid.EMPTY as unknown as Guid;
  isFirstLoad = true;

  constructor(
    public store: Store,
    private datepipe: DatePipe,
    private globalComponent: GlobalComponent,
    private commonService: CommonService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private notifier: NotificationService
  ) {}

  ngOnInit(): void {
    this.getCalendarDetail();
  }

  getDateWiseJobList(date: string): void {
    this.store
      .dispatch(new GetDateWiseJobList(date, this.userId))
      .subscribe((res) => {
        if (res.calendar.dateWiseJobList) {
          this.dateWiseJobList = res.calendar.dateWiseJobList;
        }
      });
  }

  onSearch(event: any): void {
    this.search = event.target.value;
    this.selectedDate = new Date();
    this.getCalendarDetail();
  }

  onJobAdd() {
    const dialogRef = this.dialog.open(AddJobsComponent, {
      data: {
        moduleId: this.moduleId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const jobId = this.store.selectSnapshot(JobState.getJobId);
        this.onJobEdit(jobId);
      } else {
        this.selectedDate = new Date();
        this.getCalendarDetail();
      }
    });
  }

  onJobEdit(id: any): void {
    this.commonService.onEditRouting(this.moduleId, id);
  }

  getUserId(userId): void {
    this.userId = userId;
    this.getCalendarDetail();
  }

  getJobDetails(e: any): void {
    let startDate: Date;
    if (e.name === 'moreEventsClick') {
      startDate = e.startTime;
    } else {
      startDate = e.event.StartTime;
    }
    this.jobDate = this.datepipe.transform(startDate, 'dd-MMM-yyyy');
    this.getDateWiseJobList(this.datepipe.transform(startDate, 'yyyy-MM-dd')!);
  }

  currentViewChange(e: any) {
    if (e === 'Month') {
      this.calendarViewType = CalendarType.Monthly;
    }
    if (e === 'Week') {
      this.calendarViewType = CalendarType.Weekly;
    }
    if (e === 'Day') {
      this.calendarViewType = CalendarType.Daily;
    }

    setTimeout(() => {
      if (!this.isFirstLoad) {
        this.getCalendarDetail();
      }
    }, 500);
  }

  dateChange(e: any): void {
    this.selectedDate = e;
    if (!this.isFirstLoad) {
      this.getCalendarDetail();
    }
  }

  getCalendarDetail(): void {
    this.spinner.show();
    this.calendarDetailParameters = {
      userId: this.userId,
      calendarType: this.calendarViewType,
      currentDate: this.datepipe.transform(this.selectedDate, 'yyyy-MM-dd')!,
      search: this.search,
    };
    if (
      this.calendarActionType === 'view-change' ||
      this.calendarActionType === ''
    )
      this.calendarSideList.next(this.calendarDetailParameters);

    this.store
      .dispatch(new GetCalendarDetail(this.calendarDetailParameters))
      .subscribe((res) => {
        if (res.calendar.calendarDetails) {
          this.isFirstLoad = false;
          this.setCalendarData(res.calendar.calendarDetails);
        }
      });
  }

  setCalendarData(list): void {
    let temp: Object[] = [];
    let statusColor = '';
    list.forEach((element, i) => {
      if (element.jobStatus === JobStatusModel.NotYetStarted) {
        statusColor = '#F5F5F5';
      }
      if (element.jobStatus === JobStatusModel.Completed) {
        statusColor = '#DCF6DF';
      }
      if (element.jobStatus === JobStatusModel.InProgress) {
        statusColor = '#FFE9E5';
      }
      temp.push({
        Id: element.jobId,
        Subject:
          element.jobCode + '-' + element.clientName + '-' + element.taskName,
        StartTime: new Date(element.startDate),
        EndTime: new Date(element.endDate),
        CategoryColor: statusColor,
      });
    });
    this.eventSettings = {
      dataSource: temp,
    };
  }

  onEventRendered(args: EventRenderedArgs): void {
    let categoryColor: string = args.data.CategoryColor as string;
    if (!args.element || !categoryColor) {
      return;
    }
    args.element.style.backgroundColor = categoryColor;
    args.element.style.color = '#404A45';

    let eventObj = args.data;
    args.element.innerHTML = eventObj.Subject.toString();
  }

  showSideListAction(val: any): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  onPopupOpen(args: PopupOpenEventArgs): void {
    args.cancel = true;
  }

  editJob(e: any): void {
    let jobId = this.defaultGuidValue;
    if (e && e.requestType) {
      if (e.requestType !== 'cellSelect') {
        jobId = e.data.Id;
      }
    } else {
      jobId = e.jobId;
    }
    if (jobId !== this.defaultGuidValue) {
      if (!this.checkRunningTimerData(jobId)) {
        this.commonService.onEditRouting(this.moduleId, jobId);
      }
    }
  }

  checkTimerRunning(): void {
    this.isTimerRunning =
      this.store.selectSnapshot(TimeState.isTimerRunning) ?? false;
    if (this.isTimerRunning) {
      this.runningTimerData = this.store.selectSnapshot(
        TimeState.runningTimerData
      );
    } else {
      this.runningTimerData = {};
    }
  }

  checkRunningTimerData(jobId?: any) {
    this.checkTimerRunning();
    let isDataMatch = false;
    if (this.isTimerRunning) {
      if (jobId) {
        // action on row
        if (jobId === this.runningTimerData?.jobId) {
          // timer running and match job
          isDataMatch = true;
        }
      }
    }
    if (isDataMatch) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.restrictJobActionOnRunningTimer
      );
    }
    return isDataMatch;
  }
}
