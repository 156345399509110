import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  BillableAndNonBillableHoursByMonthChartModel,
  BillableAndNonBillableHoursByMonthModel,
  BillableHoursByQuarterChartModel,
  BillableHoursByQuarterModel,
  BillableTeammateHoursModel,
  DashboardRequestModel,
  InProgressProjectByTypeModel,
  IncomeByClientChartModel,
  IncomeByClientModel,
  IncomeByPaymentMethodChartModel,
  IncomeByPaymentMethodModel,
  IncomeByProjectChartModel,
  IncomeByProjectTypeChartModel,
  IncomeByProjectTypeModel,
  IncomeByTopFiveProjectModel,
  InvoiceByPaymentLateDaysChartModel,
  InvoiceRaisedHistoryChartModel,
  InvoiceRaisedHistoryModel,
  ProjectCountByStatusModel,
  ProjectDeadlineStatusModel,
  ProjectHoursByTeamamteChartModel,
  ProjectManageByUserModel,
  ProjectStatusByTimeCostAndExpenseChartModel,
  ProjectStatusByTimeCostAndExpenseModel,
  ReportDetail,
  TaskWithMeTodayModel,
  TeamAndTaskTodayModel,
  TeamUtilizationHoursOnClientChartModel,
  TeammatesExpectedAndActualCapacityModel,
  TeammatesHoursByClientModel,
  TimeOffByTeammateModel,
  TimeOffByTeammatesChartModel,
  TimeSheetSummaryByStatusChartModel,
  TimeSheetSummaryByStatusModel,
  TopBillableTeammateHoursChartModel,
  TotalHoursByClientModel,
  UserWidgetModel,
  WeeklyScheduleModel,
} from '../../models';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'Dashboard';
  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getTeammatesExpectedAndActualCapacity(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<TeammatesExpectedAndActualCapacityModel>(
      `${environment.apiVersionUrl}/Dashboard/getTeammatesExpectedAndActualCapacity?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getListByClient(filter: any, startDate: any, endDate: any): Observable<any> {
    return this.http.get<ReportDetail>(
      `${environment.apiVersionUrl}/Dashboard/byClients?startDate=${
        startDate ?? ''
      }&endDate=${endDate ?? ''}&filter=${filter}`
    );
  }

  getByPaymentLateDays(
    filter: any,
    startDate: any,
    endDate: any
  ): Observable<any> {
    return this.http.get<ReportDetail>(
      `${environment.apiVersionUrl}/Dashboard/paymentLateDays?filter=${
        filter ?? ''
      }&startDate=${startDate ?? ''}&endDate=${endDate ?? ''}`
    );
  }

  getByPaymentMethod(
    filter: any,
    startDate: any,
    endDate: any
  ): Observable<any> {
    return this.http.get<ReportDetail>(
      `${environment.apiVersionUrl}/Dashboard/paymentMethod?startDate=${
        startDate ?? ''
      }&endDate=${endDate ?? ''}&filter=${filter}`
    );
  }

  getByStatus(filter: any, startDate: any, endDate: any): Observable<any> {
    return this.http.get<ReportDetail>(
      `${environment.apiVersionUrl}/Dashboard/byStatus?startDate=${
        startDate ?? ''
      }&endDate=${endDate ?? ''}&filter=${filter}`
    );
  }

  getByTask(filter: any, startDate: any, endDate: any): Observable<any> {
    return this.http.get<ReportDetail>(
      `${environment.apiVersionUrl}/Dashboard/byTask?startDate=${
        startDate ?? ''
      }&endDate=${endDate ?? ''}&filter=${filter}`
    );
  }

  getByTimeSpentOnClients(
    filter: any,
    startDate: any,
    endDate: any
  ): Observable<any> {
    return this.http.get<ReportDetail>(
      `${environment.apiVersionUrl}/Dashboard/byTimeSpentOnClients?startDate=${
        startDate ?? ''
      }&endDate=${endDate ?? ''}&filter=${filter}`
    );
  }

  getByRateOfTimeSpentByHoursOnTasks(
    filter: any,
    startDate: any,
    endDate: any
  ): Observable<any> {
    return this.http.get<ReportDetail>(
      `${
        environment.apiVersionUrl
      }/Dashboard/byRateOfTimeSpentByHoursOnTasks?startDate=${
        startDate ?? ''
      }&endDate=${endDate ?? ''}&filter=${filter}`
    );
  }

  getByTeamUtilizeHoursOnTasks(
    filter: any,
    startDate: any,
    endDate: any
  ): Observable<any> {
    return this.http.get<ReportDetail>(
      `${
        environment.apiVersionUrl
      }/Dashboard/byTeamUtilizeHoursOnTasks?startDate=${
        startDate ?? ''
      }&endDate=${endDate ?? ''}&filter=${filter}`
    );
  }

  getByRateOfTimeSpentByGbpOnTasks(
    filter: any,
    startDate: any,
    endDate: any
  ): Observable<any> {
    return this.http.get<ReportDetail>(
      `${
        environment.apiVersionUrl
      }/Dashboard/byRateOfTimeSpentByGbpOnTasks?startDate=${
        startDate ?? ''
      }&endDate=${endDate ?? ''}&filter=${filter}`
    );
  }

  // New Dashboard Changes

  getTeamAndTaskToday(selectedDate: string): Observable<any> {
    return this.http.get<TeamAndTaskTodayModel>(
      `${environment.apiVersionUrl}/Dashboard/getTeamAndTaskToday?selectedDate=${selectedDate}`
    );
  }

  getTaskWithMeToday(selectedDate: string): Observable<any> {
    return this.http.get<TaskWithMeTodayModel>(
      `${environment.apiVersionUrl}/Dashboard/getTaskWithMeToday?selectedDate=${selectedDate}`
    );
  }

  getProjectDeadlineStatus(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<ProjectDeadlineStatusModel>(
      `${environment.apiVersionUrl}/Dashboard/getProjectDeadlineStatus?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getInProgressProjectsByType(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<InProgressProjectByTypeModel>(
      `${environment.apiVersionUrl}/Dashboard/getInProgressProjectsByType?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getProjectStatusByTimeCostAndExpense(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<ProjectStatusByTimeCostAndExpenseModel>(
      `${environment.apiVersionUrl}/Dashboard/getProjectStatusByTimeCostAndExpense?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getIncomeByTopFiveProjects(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<IncomeByTopFiveProjectModel>(
      `${environment.apiVersionUrl}/Dashboard/getIncomeByTopFiveProjects?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getIncomeByClient(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<IncomeByClientModel>(
      `${environment.apiVersionUrl}/Dashboard/getIncomeByClient?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getIncomeByProjectType(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<IncomeByProjectTypeModel>(
      `${environment.apiVersionUrl}/Dashboard/getIncomeByProjectType?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getBillableTeammateByHours(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<BillableTeammateHoursModel>(
      `${environment.apiVersionUrl}/Dashboard/getBillableTeammateByHours?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getBillableAndNonBillableHoursByMonth(selectedYear: string): Observable<any> {
    return this.http.get<BillableAndNonBillableHoursByMonthModel>(
      `${environment.apiVersionUrl}/Dashboard/getBillableAndNonBillableHoursByMonth?year=${selectedYear}`
    );
  }

  getIncomeByPaymentMethods(selectedYear: string): Observable<any> {
    return this.http.get<IncomeByPaymentMethodModel>(
      `${environment.apiVersionUrl}/Dashboard/getIncomeByPaymentMethods?year=${selectedYear}`
    );
  }

  getTeamUtilizationHoursOnClients(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<TeammatesHoursByClientModel>(
      `${environment.apiVersionUrl}/Dashboard/getTeamUtilizationHoursOnClients?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getTotalProjectHoursByTeammate(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<TotalHoursByClientModel>(
      `${environment.apiVersionUrl}/Dashboard/getTotalProjectHoursByTeammate?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getBillableHoursByQuarterAndByClient(selectedYear: string): Observable<any> {
    return this.http.get<BillableHoursByQuarterModel>(
      `${environment.apiVersionUrl}/Dashboard/getBillableHoursByQuarterAndByClient?year=${selectedYear}`
    );
  }

  getTimeSheetSummaryByStatus(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<TimeSheetSummaryByStatusModel>(
      `${environment.apiVersionUrl}/Dashboard/getTimeSheetSummaryByStatus?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}&userId=${dashboardRequestModel.userId}&projectId=${dashboardRequestModel.projectId}`
    );
  }

  getTimeOffByTeammate(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<TimeOffByTeammateModel>(
      `${environment.apiVersionUrl}/Dashboard/getTimeOffByTeammate?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getInvoiceHistory(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<InvoiceRaisedHistoryModel>(
      `${environment.apiVersionUrl}/Dashboard/getInvoiceHistory?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getProjectsCountByStatus(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<ProjectCountByStatusModel>(
      `${environment.apiVersionUrl}/Dashboard/getProjectsCountByStatus?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getProjectManageByUser(selectedDate: string): Observable<any> {
    return this.http.get<ProjectManageByUserModel>(
      `${environment.apiVersionUrl}/Dashboard/getProjectManageByUser?selectedDate=${selectedDate}`
    );
  }

  getInvoiceHistoryGraphical(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<InvoiceRaisedHistoryChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getInvoiceHistoryGraphical?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getIncomeByProjectTypeGraphical(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<IncomeByProjectTypeChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getIncomeByProjectTypeGraphical?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getIncomeByClientGraphical(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<IncomeByClientChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getIncomeByClientGraphical?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getBillableHoursByQuarterAndByClientGraphical(
    selectedYear: string
  ): Observable<any> {
    return this.http.get<BillableHoursByQuarterChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getBillableHoursByQuarterAndByClientGraphical?year=${selectedYear}`
    );
  }

  getTeamUtilizationHoursOnClientGraphical(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<TeamUtilizationHoursOnClientChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getTeamUtilizationHoursOnClientGraphical?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getTotalProjectHoursByTeammateGraphical(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<ProjectHoursByTeamamteChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getTotalProjectHoursByTeammateGraphical?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getBillableTeammateByHoursGraphical(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<TopBillableTeammateHoursChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getBillableTeammateByHoursGraphical?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getIncomeByProjectGraphical(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<IncomeByProjectChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getIncomeByProjectGraphical?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getIncomeByPaymentMethodsGraphical(selectedYear: string): Observable<any> {
    return this.http.get<IncomeByPaymentMethodChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getIncomeByPaymentMethodsGraphical?year=${selectedYear}`
    );
  }

  getBillableAndNonBillableHoursByMonthGraphical(
    selectedYear: string
  ): Observable<any> {
    return this.http.get<BillableAndNonBillableHoursByMonthChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getBillableAndNonBillableHoursByMonthGraphical?year=${selectedYear}`
    );
  }

  getNumberOfOngoingProjects(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}/Dashboard/getNumberOfOngoingProjects?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getInvoicedThisYearAsOnDate(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}/Dashboard/getInvoicedThisYearAsOnDate?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getAmountReceivedThisYearAsOnDate(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}/Dashboard/getAmountReceivedThisYearAsOnDate?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getAmountDueThisYearAsOnDate(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}/Dashboard/getAmountDueThisYearAsOnDate?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getInvoiceByPaymentLateDaysGraphical(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<InvoiceByPaymentLateDaysChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getInvoiceByPaymentLateDaysGraphical?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getProjectStatusByTimeCostAndExpenseGraphical(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<ProjectStatusByTimeCostAndExpenseChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getProjectStatusByTimeCostAndExpenseGraphical?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getTimeSheetSummaryByStatusGraphical(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<TimeSheetSummaryByStatusChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getTimeSheetSummaryByStatusGraphical?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getTimeOffByTeammateGraphical(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<TimeOffByTeammatesChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getTimeOffByTeammateGraphical?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&filter=${dashboardRequestModel.filter}`
    );
  }

  getExpenseByStatus(status: number): Observable<any> {
    return this.http.get<TimeOffByTeammatesChartModel>(
      `${environment.apiVersionUrl}/Dashboard/getExpenseByStatus?status=${status}`
    );
  }

  getWeeklySchedule(): Observable<any> {
    return this.http.get<WeeklyScheduleModel>(
      `${environment.apiVersionUrl}/Dashboard/getWeeklySchedule`
    );
  }

  // 2.0

  getUserWidgets(): Observable<any> {
    return this.http.get<UserWidgetModel>(
      `${this.apiUrl}${this.routeBase}/getUserWidgets`
    );
  }

  getTimeDashboardSummary(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<TimeSheetSummaryByStatusModel>(
      `${this.apiUrl}${this.routeBase}/getTimeDashboardSummary?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&dateFilter=${dashboardRequestModel.filter}`
    );
  }

  getMostAndLeastCount(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<TimeSheetSummaryByStatusModel>(
      `${this.apiUrl}${this.routeBase}/getMostAndLeastCount?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&dateFilter=${dashboardRequestModel.filter}`
    );
  }

  saveUserWidgets(userWidgetModel: Array<UserWidgetModel>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveUserWidgets`,
      JSON.stringify(userWidgetModel),
      headers
    );
  }

  deleteUserWidgetByWidgetId(widgetId: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: widgetId,
    };
    return this.http.delete<any>(
      `${this.apiUrl}${this.routeBase}/deleteUserWidgetByWidgetId`,
      options
    );
  }

  getBillableAndNonBillableHours(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<TimeSheetSummaryByStatusModel>(
      `${this.apiUrl}${this.routeBase}/getBillableAndNonBillableHours?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&dateFilter=${dashboardRequestModel.filter}`
    );
  }

  getTaskWiseHoursDetails(
    dashboardRequestModel: DashboardRequestModel,
    taskId: Guid
  ): Observable<any> {
    return this.http.get<TimeSheetSummaryByStatusModel>(
      `${this.apiUrl}${this.routeBase}/getTaskWiseHoursDetails?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&dateFilter=${dashboardRequestModel.filter}&taskId=${taskId}`
    );
  }

  getTimeOffByUsers(
    dashboardRequestModel: DashboardRequestModel
  ): Observable<any> {
    return this.http.get<TimeSheetSummaryByStatusModel>(
      `${this.apiUrl}${this.routeBase}/getTimeOffByUsers?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&dateFilter=${dashboardRequestModel.filter}`
    );
  }

  getOverallHoursDetail(
    dashboardRequestModel: DashboardRequestModel,
    taskId: Guid
  ): Observable<any> {
    return this.http.get<TimeSheetSummaryByStatusModel>(
      `${this.apiUrl}${this.routeBase}/getOverallHoursDetail?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&dateFilter=${dashboardRequestModel.filter}&taskId=${taskId}`
    );
  }

  getJobCount(dashboardRequestModel: DashboardRequestModel): Observable<any> {
    return this.http.get<TimeSheetSummaryByStatusModel>(
      `${this.apiUrl}${this.routeBase}/getJobCount?startDate=${dashboardRequestModel.startDate}&endDate=${dashboardRequestModel.endDate}&dateFilter=${dashboardRequestModel.filter}`
    );
  }

  revertToDefaultDashboardSetting(): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/revertToDefaultDashboardSetting`,
      headers
    );
  }
}
