import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  DashboardFilterTypes,
  DashboardWidget,
  DecreaseSummaryMessage,
  IncreaseSummaryMessage,
  NotificationTextMessage,
} from '@app/core/enums';
import {
  DashboardRequestModel,
  FilterTypes,
  InvoiceOverviewRequestModel,
  JobSummaryModel,
  MostLeastCountModel,
  SideListModel,
  TimeDashboardSummary,
  UserWidgetModel,
} from '@app/core/models';
import { CommonService } from '@app/core/services';
import {
  DashboardState,
  DeleteUserWidgetByWidgetId,
  GetAllTasks,
  GetInvoiceOverviewCardData,
  GetJobCount,
  GetMostAndLeastCount,
  GetSettingsData,
  GetStartWeekAndEndWeek,
  GetTimeDashboardSummary,
  GetUserWidgets,
  LockUnlockDashboard,
  SaveUserWidgets,
  SettingsState,
} from '@app/core/store';
import { GetAvailableJobByRole } from '@app/core/store/job';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

export const MY_DATE_FORMATS = {
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-dashboard-new',
  templateUrl: './dashboard-new.component.html',
  styleUrls: ['./dashboard-new.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class DashboardNewComponent {
  public layoutColor: string;
  public cellSpacing: number[] = [15, 15];
  public cellAspectRatio: number = 1.5;
  lockDashboard = false;

  showWidgetInfo = false;
  showWidgetDetails = false;
  showDashboard = true;
  userWidgetsList: UserWidgetModel[];
  timeSectionChartList: UserWidgetModel[];
  feeSectionChartList: UserWidgetModel[];
  filterSelectionId = 1;
  filter = 1;
  showCalendar = false;
  calendarForm: FormGroup;
  startDate = new FormControl({ value: new Date(), disabled: true });
  endDate = new FormControl({ value: new Date(), disabled: true });
  start: string = '';
  end: string = '';
  filterTypes = FilterTypes;
  dashboardRequestModel: DashboardRequestModel;
  timeDashboardSummary: TimeDashboardSummary;
  feesDashboardSummary: any;
  mostLeastCount: MostLeastCountModel;
  dashboardWidget = DashboardWidget;
  triggerFilterData: BehaviorSubject<any> = new BehaviorSubject<any>({
    dateFilter: this.filter ?? 0,
    startDate: this.start,
    endDate: this.end,
  });

  jobCount: any = [];
  jobList: any = [];
  defaultGuid = Guid.EMPTY as unknown as Guid;
  jobId = Guid.EMPTY as unknown as Guid;
  taskList: SideListModel[];
  taskId = Guid.EMPTY as unknown as Guid;
  taskIdForHoursDetails = Guid.EMPTY as unknown as Guid;
  jobData: JobSummaryModel;
  isAllTimeChartsSelected = false;
  isAllFeesChartsSelected = false;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  triggerTaskId: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  triggerTaskIdForHoursDetails: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  triggerWidgetList: Subject<any> = new Subject<any>();
  @Output()
  readonly showTimer = new EventEmitter<any>();

  dashboardFilterTypes = DashboardFilterTypes;
  clientSummaryMessage = '';
  taskSummaryMessage = '';
  userSummaryMessage = '';
  timeSummaryMessage = '';
  totalInvoiceSummaryMessage = '';
  paidAmountSummaryMessage = '';
  unpaidAmountsummaryMessage = '';
  overdueAmountSummaryMessage = '';
  messageMappings = {
    0: {
      ThisWeek: DecreaseSummaryMessage.ThisWeek,
      LastWeek: DecreaseSummaryMessage.LastWeek,
      ThisMonth: DecreaseSummaryMessage.ThisMonth,
      LastMonth: DecreaseSummaryMessage.LastMonth,
      ThisQuarter: DecreaseSummaryMessage.ThisQuarter,
      LastQuarter: DecreaseSummaryMessage.LastQuarter,
      ThisYear: DecreaseSummaryMessage.ThisYear,
      LastYear: DecreaseSummaryMessage.LastYear,
    },
    1: {
      ThisWeek: IncreaseSummaryMessage.ThisWeek,
      LastWeek: IncreaseSummaryMessage.LastWeek,
      ThisMonth: IncreaseSummaryMessage.ThisMonth,
      LastMonth: IncreaseSummaryMessage.LastMonth,
      ThisQuarter: IncreaseSummaryMessage.ThisQuarter,
      LastQuarter: IncreaseSummaryMessage.LastQuarter,
      ThisYear: IncreaseSummaryMessage.ThisYear,
      LastYear: IncreaseSummaryMessage.LastYear,
    },
  };

  invoiceOverviewRequestModel: InvoiceOverviewRequestModel;
  constructor(
    private store: Store,
    public formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getSettingsData();
    this.getUserWidgets();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 800);
  }

  addWidgetClick(): void {
    this.triggerWidgetList.next();
  }

  onRefreshDashboard(): void {
    this.trigger.closeMenu();
    this.getUserWidgets();
  }

  getSettingsData(): void {
    this.store
      .dispatch(new GetSettingsData())
      .pipe(
        tap(() => {
          this.showWidgetInfo = !this.store.selectSnapshot(
            SettingsState.getSettingData
          ).isDashboardCreated!;

          this.showDashboard = this.store.selectSnapshot(
            SettingsState.getSettingData
          ).isDashboardCreated!;

          if (this.showDashboard) {
            setTimeout(() => {
              this.bindDashboardCharts();
            }, 1500);
          }
        })
      )
      .subscribe();
  }

  onStartTimerClick() {
    if (this.jobId !== this.defaultGuid) {
      let data = {
        clientId: this.jobData.clientId,
        clientName: this.jobData.clientName,
        jobId: this.jobData.universalId,
        jobCode: this.jobData.jobCode,
        taskId: this.jobData.taskId,
        taskName: this.jobData.taskName,
      };
      const timerData = {
        showTimer: true,
        jobData: data,
      };
      this.commonService.showTimerPopup(timerData);
    } else {
      this.commonService.onFailure(
        NotificationTextMessage.selectJobToStartTimer
      );
    }
  }

  onJobSelectionChange(jobId): void {
    this.jobId = jobId;
    this.jobList.forEach((element) => {
      if (element.universalId === jobId) {
        this.jobData = element;
      }
    });
  }

  getAvailableJobByRole(): void {
    this.store
      .dispatch(new GetAvailableJobByRole())
      .pipe(
        tap((res) => {
          this.jobList = res.job.allAvailableJobByRole;
        })
      )
      .subscribe();
  }

  onAddWidget(): void {
    this.showWidgetInfo = true;
    this.showWidgetDetails = false;
    this.showDashboard = false;
    this.getUserWidgets();
  }

  getTimesheetSummary(): void {
    this.dashboardRequestModel = {
      filter: this.filter ?? 0,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
    };

    this.store
      .dispatch(new GetTimeDashboardSummary(this.dashboardRequestModel))
      .pipe(
        tap(() => {
          this.timeDashboardSummary = this.store.selectSnapshot(
            DashboardState.getTimeDashboardSummary
          )!;
          this.setMessageForSummary();
        })
      )
      .subscribe();
  }

  setMessageForSummary(): void {
    this.clientSummaryMessage = '';
    this.taskSummaryMessage = '';
    this.userSummaryMessage = '';
    this.timeSummaryMessage = '';

    const clientScore = this.timeDashboardSummary?.clientScore;
    const taskScore = this.timeDashboardSummary?.taskScore;
    const userScore = this.timeDashboardSummary?.userScore;
    const timeScore = this.timeDashboardSummary?.timeScore;
    const filterType = this.dashboardFilterTypes[this.filterSelectionId];

    if (
      clientScore !== undefined &&
      this.messageMappings[clientScore][filterType]
    ) {
      this.clientSummaryMessage = this.messageMappings[clientScore][filterType];
    }

    if (
      taskScore !== undefined &&
      this.messageMappings[taskScore][filterType]
    ) {
      this.taskSummaryMessage = this.messageMappings[taskScore][filterType];
    }

    if (
      userScore !== undefined &&
      this.messageMappings[userScore][filterType]
    ) {
      this.userSummaryMessage = this.messageMappings[userScore][filterType];
    }

    if (
      timeScore !== undefined &&
      this.messageMappings[timeScore][filterType]
    ) {
      this.timeSummaryMessage = this.messageMappings[timeScore][filterType];
    }
  }

  getMostAndLeastCount(): void {
    this.dashboardRequestModel = {
      filter: this.filter ?? 0,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
    };

    this.store
      .dispatch(new GetMostAndLeastCount(this.dashboardRequestModel))
      .pipe(
        tap(() => {
          this.mostLeastCount = this.store.selectSnapshot(
            DashboardState.getMostandLeastCount
          )!;
        })
      )
      .subscribe();
  }

  getJobCount(): void {
    this.dashboardRequestModel = {
      filter: this.filter ?? 0,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
    };

    this.store
      .dispatch(new GetJobCount(this.dashboardRequestModel))
      .pipe(
        tap((res) => {
          this.jobCount = res.dashboard.jobCount;
        })
      )
      .subscribe();
  }

  getUserWidgets(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetUserWidgets())
      .pipe(
        tap((res) => {
          this.userWidgetsList = this.store.selectSnapshot(
            DashboardState.getUserWidgets
          );

          this.lockDashboard = this.store.selectSnapshot(
            DashboardState.isDashboardLocked
          );

          this.timeSectionChartList = this.userWidgetsList.filter(
            (x) => x.widgetTypeId === 1
          );
          this.feeSectionChartList = this.userWidgetsList.filter(
            (x) => x.widgetTypeId === 2
          );

          this.isAllTimeChartsSelected =
            this.timeSectionChartList.length > 0
              ? this.timeSectionChartList.every(
                  (item: any) => item.isEnable === true
                )
              : false;

          this.isAllFeesChartsSelected =
            this.feeSectionChartList.length > 0
              ? this.feeSectionChartList.every(
                  (item: any) => item.isEnable === true
                )
              : false;
        })
      )
      .subscribe(() => {});
  }

  createDashboard(): void {
    this.spinner.show();
    const userWidgetModel = this.userWidgetsList;
    this.store.dispatch(new SaveUserWidgets(userWidgetModel)).subscribe(() => {
      this.showDashboard = true;
      this.showWidgetDetails = false;
      this.showWidgetInfo = false;
      this.bindDashboardCharts();
    });
  }

  bindDashboardCharts(): void {
    this.setForm();
    this.getTimesheetSummary();
    this.getMostAndLeastCount();
    this.getJobCount();
    this.getAvailableJobByRole();
    this.getTasks();
    this.getFeesDashboardSummary();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 800);
  }

  getTasks(): void {
    this.store
      .dispatch(new GetAllTasks())
      .pipe(
        tap((res) => {
          this.taskList = res.task.allTasks;
        })
      )
      .subscribe();
  }

  onTaskSelectionChange(taskId): void {
    this.taskId = taskId;
    this.triggerTaskId.next(this.taskId);
  }

  onSelectionForTaskWiseHours(taskId): void {
    this.triggerTaskIdForHoursDetails.next(taskId);
  }

  selectTimeSectionChartWidget(): void {
    this.isAllTimeChartsSelected = this.timeSectionChartList.every(
      (item: any) => item.isEnable === true
    );
  }

  selectFeeSectionChartWidget(): void {
    this.isAllFeesChartsSelected = this.feeSectionChartList.every(
      (item: any) => item.isEnable === true
    );
  }

  setForm(): void {
    this.calendarForm = this.formBuilder.group({
      start: new FormControl<string | null>(''),
      end: new FormControl<string | null>(''),
    });
  }

  onSelectionChange(val: any): void {
    this.filter = +val;
    if (this.filter === 9) {
      this.showCalendar = true;
      this.getStartWeekAndEndWeek();
    } else {
      this.showCalendar = false;
      this.dataChangeFromHeader();
    }
  }

  getStartWeekAndEndWeek(): void {
    this.store.dispatch(new GetStartWeekAndEndWeek()).subscribe((res) => {
      this.startDate.setValue(
        res.setting.startWeekAndEndWeek.startDate ?? null
      );
      this.endDate.setValue(res.setting.startWeekAndEndWeek.endDate ?? null);

      this.calendarForm = this.formBuilder.group({
        start: new FormControl<Date | null>(this.startDate.value),
        end: new FormControl<Date | null>(this.endDate.value),
      });

      this.dataChangeFromHeader();
    });
  }

  dataChangeFromHeader(): void {
    this.end =
      this.filter < 9
        ? ''
        : this.datepipe.transform(
            this.calendarForm.controls.end.value,
            'yyyy-MM-dd'
          )!;
    this.start =
      this.filter < 9
        ? ''
        : this.datepipe.transform(
            this.calendarForm.controls.start.value,
            'yyyy-MM-dd'
          )!;
    this.getTimesheetSummary();
    this.getMostAndLeastCount();
    this.getJobCount();
    this.getFeesDashboardSummary();
    let filterData = {
      dateFilter: this.filter ?? 0,
      startDate: this.start,
      endDate: this.end,
    };
    this.triggerFilterData.next(filterData);
  }

  dateRangeChange(dateRangeStart: any, dateRangeEnd: any): void {
    if (dateRangeStart !== '' && dateRangeEnd !== '') {
      this.dataChangeFromHeader();
    }
  }

  lessWeek(): void {
    this.calendarForm = this.formBuilder.group({
      end: new Date(
        new Date(this.calendarForm.controls.start.value).setDate(
          new Date(this.calendarForm.controls.start.value).getDate() - 1
        )
      ),
      start: new Date(
        new Date(this.calendarForm.controls.start.value).setDate(
          new Date(this.calendarForm.controls.start.value).getDate() - 7
        )
      ),
    });

    this.startDate.setValue(this.calendarForm.controls.start.value);
    this.endDate.setValue(this.calendarForm.controls.end.value);
    this.dataChangeFromHeader();
  }

  addWeek(): void {
    this.calendarForm = this.formBuilder.group({
      start: new Date(
        new Date(this.calendarForm.controls.end.value).setDate(
          new Date(this.calendarForm.controls.end.value).getDate() + 1
        )
      ),
      end: new Date(
        new Date(this.calendarForm.controls.end.value).setDate(
          new Date(this.calendarForm.controls.end.value).getDate() + 7
        )
      ),
    });
    this.dataChangeFromHeader();
  }

  onCloseIcon(widget: any): void {
    this.store
      .dispatch(new DeleteUserWidgetByWidgetId(widget.widgetId))
      .pipe(
        tap((res) => {
          if (res.dashboard.isWidgetDeleted) {
            this.userWidgetsList.forEach((element) => {
              if (element.widgetId === widget.widgetId) {
                element.isEnable = false;
              }
            });

            this.commonService.onSuccess(
              NotificationTextMessage.successMessage
            );
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        })
      )
      .subscribe();
  }

  getFeesDashboardSummary(): void {
    this.invoiceOverviewRequestModel = {
      dateFilter: this.filter ?? 0,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
    };
    this.store
      .dispatch(
        new GetInvoiceOverviewCardData(this.invoiceOverviewRequestModel)
      )
      .pipe()
      .subscribe((res) => {
        this.feesDashboardSummary = res.overview.invoiceOverviewCardData;
        this.setMessageForFeeSummary();
      });
  }

  setMessageForFeeSummary(): void {
    this.totalInvoiceSummaryMessage = '';
    this.paidAmountSummaryMessage = '';
    this.unpaidAmountsummaryMessage = '';
    this.overdueAmountSummaryMessage = '';

    const totalInvoiceScore = this.feesDashboardSummary?.totalInvoiceScore;
    const totalPaidInvoiceScore =
      this.feesDashboardSummary?.totalPaidInvoiceScore;
    const totalUnPaidInvoiceScore =
      this.feesDashboardSummary?.totalUnPaidInvoiceScore;
    const totalOverdueScore = this.feesDashboardSummary?.totalOverdueScore;
    const filterType = this.dashboardFilterTypes[this.filterSelectionId];

    if (
      totalInvoiceScore !== undefined &&
      this.messageMappings[totalInvoiceScore][filterType]
    ) {
      this.totalInvoiceSummaryMessage =
        this.messageMappings[totalInvoiceScore][filterType];
    }

    if (
      totalPaidInvoiceScore !== undefined &&
      this.messageMappings[totalPaidInvoiceScore][filterType]
    ) {
      this.paidAmountSummaryMessage =
        this.messageMappings[totalPaidInvoiceScore][filterType];
    }

    if (
      totalUnPaidInvoiceScore !== undefined &&
      this.messageMappings[totalUnPaidInvoiceScore][filterType]
    ) {
      this.unpaidAmountsummaryMessage =
        this.messageMappings[totalUnPaidInvoiceScore][filterType];
    }

    if (
      totalOverdueScore !== undefined &&
      this.messageMappings[totalOverdueScore][filterType]
    ) {
      this.overdueAmountSummaryMessage =
        this.messageMappings[totalOverdueScore][filterType];
    }
  }

  selectAllTimeCharts(event: any): void {
    this.timeSectionChartList.forEach((x) => (x.isEnable = event.checked));
  }

  selectAllFeesCharts(event: any): void {
    this.feeSectionChartList.forEach((x) => (x.isEnable = event.checked));
  }

  onCancelClick(): void {
    this.trigger.closeMenu();
  }

  onDragStop(event: any): void {}

  cards = [
    { title: 'Card 1' },
    { title: 'Card 2' },
    { title: 'Card 3' },
    { title: 'Card 4' },
    { title: 'Card 5' },
    { title: 'Card 6' },
    { title: 'Card 7' },
    { title: 'Card 8' },
    { title: 'Card 9' },
    { title: 'Card 10' },
    { title: 'Card 11' },
    { title: 'Card 12' },

    { title: 'Card 13' },
    { title: 'Card 14' },
    { title: 'Card 15' },

    { title: 'Card 16' },
    { title: 'Card 17' },
    { title: 'Card 18' },

    { title: 'Card 19' },
    { title: 'Card 20' },
  ];

  entered($event: CdkDragEnter) {
    moveItemInArray(
      this.userWidgetsList,
      $event.item.data,
      $event.container.data
    );
  }

  @ViewChildren(CdkDropList) dropsQuery: QueryList<CdkDropList> =
    new QueryList<CdkDropList>();

  drops: CdkDropList[] = [];

  ngAfterViewInit() {
    this.dropsQuery.changes.subscribe(() => {
      this.drops = this.dropsQuery.toArray();
    });
    Promise.resolve().then(() => {
      this.drops = this.dropsQuery.toArray();
      console.log(this.drops);
    });
  }

  setCardWidth(widget: any): any {
    if (
      widget.widgetId === DashboardWidget.TimesheetSummary ||
      widget.widgetId === DashboardWidget.MostvsLeastCounts ||
      widget.widgetId === DashboardWidget.FeesSummary
    )
      return '100%';
    if (
      widget.widgetId === DashboardWidget.TaskWiseHoursDetails ||
      widget.widgetId === DashboardWidget.OverallHoursDetails ||
      widget.widgetId === DashboardWidget.TimeoffByUser ||
      widget.widgetId === DashboardWidget.IncomeTrend
    )
      return '67%';
    if (
      widget.widgetId === DashboardWidget.InvoiceAmountByInvoiceMonth ||
      widget.widgetId === DashboardWidget.BalanceAmountByDueMonth
    )
      return '50%';
    if (
      widget.widgetId === DashboardWidget.TimerWidget ||
      widget.widgetId === DashboardWidget.BillableNonBillableHours ||
      widget.widgetId === DashboardWidget.JobCounts ||
      widget.widgetId === DashboardWidget.RevenueFlow ||
      widget.widgetId === DashboardWidget.InvoiceAmountByCategory ||
      widget.widgetId === DashboardWidget.TopClientsByInvoiceAmount ||
      widget.widgetId === DashboardWidget.TopClientsWithBalance ||
      widget.widgetId === DashboardWidget.PaymentMethods ||
      widget.widgetId === DashboardWidget.EstimatesStatus ||
      widget.widgetId === DashboardWidget.InvoiceStatus
    )
      return '33%';
  }

  drop(event: CdkDragDrop<any>) {
    console.log('previousIndex:', event.previousIndex);
    console.log('currentIndex: ', event.currentIndex);
    console.log('data: ', event.container.data);
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  onLockUnlockDashboard(): void {
    this.lockDashboard = !this.lockDashboard;

    this.store.dispatch(new LockUnlockDashboard(this.lockDashboard)).subscribe(
      () => {
        this.commonService.onSuccess(
          this.lockDashboard
            ? NotificationTextMessage.dashboardLockedMessage
            : NotificationTextMessage.dashboardUnlockedMessage
        );
      },
      (err) => {
        this.commonService.onFailure(NotificationTextMessage.errorMessage);
      }
    );
  }
}
