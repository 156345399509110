<div class="comingsoon d-none">
  <div class="text-align-center">
    <img src="assets/images/comingsoon.svg" class="mr-1" alt="Coming Soon" />
    <h2>Coming Soon!</h2>
    <p>This page is presently being built diligently by us.</p>
  </div>
</div>

<div class="dashboard-wrapper">
  <div class="widget-box text-align-center" *ngIf="showWidgetDetails">
    <div class="card border-none">
      <img src="assets/images/widget-img.svg" class="mr-1" alt="Danger" />
      <p class="mt-2">
        Click on <strong>‘Add widget’</strong> button to customise your
        dashboard.
      </p>
      <div class="widget-button mt-2">
        <button
          class="action-button primary-button"
          mat-button
          disableRipple
          (click)="onAddWidget()"
        >
          <mat-icon>add</mat-icon>
          <span>Add Widget</span>
        </button>
      </div>
    </div>
  </div>
  <div class="widget-box" *ngIf="showWidgetInfo">
    <div class="add-widget-box">
      <div class="text-align-center mb-0">
        <h2 class="font-size-18 fw-bold mb-0">
          Let’s Customise your Dashboard!
        </h2>
        <p class="font-size-14 mb-5">
          Please select the below charts to add in your Dashboard
        </p>
      </div>
      <div class="card wp-100">
        <div class="d-flex justify-space-between">
          <div class="wp-100">
            <div class="title d-flex align-items-center">
              <mat-checkbox
                [(ngModel)]="isAllTimeChartsSelected"
                (change)="selectAllTimeCharts($event)"
              >
                <h2>Time Section Charts</h2>
              </mat-checkbox>
            </div>

            <div class="wlist-box">
              <div *ngFor="let widget of timeSectionChartList">
                <mat-checkbox
                  (change)="selectTimeSectionChartWidget()"
                  [(ngModel)]="widget.isEnable"
                >
                  {{ widget.widgetName }}
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="wp-100">
            <div class="title d-flex align-items-center">
              <mat-checkbox
                [(ngModel)]="isAllFeesChartsSelected"
                (change)="selectAllFeesCharts($event)"
              >
                <h2>Fees Section Charts</h2>
              </mat-checkbox>
            </div>

            <div class="wlist-box">
              <div *ngFor="let widget of feeSectionChartList">
                <mat-checkbox
                  (change)="selectFeeSectionChartWidget()"
                  [(ngModel)]="widget.isEnable"
                >
                  {{ widget.widgetName }}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="widget-button mt-10 text-align-center">
        <button
          class="action-button primary-button"
          mat-button
          disableRipple
          (click)="createDashboard()"
        >
          <span>Create Dashboard</span>
        </button>
      </div>
    </div>
  </div>
  <div class="sidenav-body-content" *ngIf="!showWidgetDetails && showDashboard">
    <div class="content-header mb-1">
      <div class="content-header-input pl-10">
        <button
          class="action-button primary-button"
          mat-button
          disableRipple
          [matMenuTriggerFor]="menuManages"
          (click)="addWidgetClick()"
          [disabled]="lockDashboard"
        >
          <mat-icon>add</mat-icon>
          <span>Add Widget</span>
        </button>
        <mat-menu #menuManages="matMenu" class="widget-menu" xPosition="before">
          <div (click)="$event.stopPropagation()">
            <app-widget-list
              [triggerWidgetList]="triggerWidgetList"
              (triggerRefreshDashboard)="onRefreshDashboard()"
              (triggerOnCancelClick)="onCancelClick()"
            ></app-widget-list>
          </div>
        </mat-menu>
      </div>
      <div class="content-right-button pr-10">
        <div class="sidenav-content d-flex align-items-center gap-15">
          <ng-select
            class="w-120 month-filter"
            [disableOptionCentering]="true"
            #month
            [(ngModel)]="filterSelectionId"
            (focus)="month.open()"
            (valueChange)="month.close()"
            (change)="onSelectionChange($event)"
          >
            <ng-option *ngFor="let filter of filterTypes" [value]="filter.id">
              {{ filter.name }}</ng-option
            >
          </ng-select>
          <form [formGroup]="calendarForm" *ngIf="showCalendar">
            <div class="pb-0 text-align-right">
              <mat-form-field class="w-295">
                <mat-date-range-input [rangePicker]="picker">
                  <input
                    matStartDate
                    matInput
                    [value]="startDate.value"
                    formControlName="start"
                    #start
                    readonly
                  />
                  <input
                    matEndDate
                    matInput
                    [value]="endDate.value"
                    formControlName="end"
                    #end
                    readonly
                    (dateChange)="dateRangeChange(start.value, end.value)"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matPrefix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <div class="mar-daterange-arrow">
                  <button (click)="lessWeek()" type="button" disableRipple>
                    <mat-icon>keyboard_arrow_left</mat-icon>
                  </button>
                  <button (click)="addWeek()" type="button" disableRipple>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </button>
                </div>
              </mat-form-field>
            </div>
          </form>
          <div class="column-wrapper">
            <button
              type="button"
              mat-button
              disableRipple
              title="Lock"
              (click)="onLockUnlockDashboard()"
            >
              <mat-icon *ngIf="!lockDashboard" fontSet="material-icons-outlined"
                >lock</mat-icon
              >
              <mat-icon *ngIf="lockDashboard" fontSet="material-icons-outlined"
                >lock_open</mat-icon
              >
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="control-section">
      <div>
        <div class="dragndrop-list">
          <cdk-drop-list
            *ngFor="let widget of userWidgetsList; let i = index"
            [cdkDropListConnectedTo]="drops"
            [cdkDropListData]="i"
            [style.flex-basis]="setCardWidth(widget)"
          >
            <div
              *ngIf="
                widget.widgetId === dashboardWidget.TimesheetSummary &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card mt-0 p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">Time Summary</div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>

                    <div class="card-wrapper p-1">
                      <div class="card">
                        <div class="card-body">
                          <div class="card-text">
                            <h2 class="font-size-16 fw-normal text-gray pb-5">
                              No.of Clients Worked on
                            </h2>
                            <span class="font-size-24 fw-bold"
                              >{{ timeDashboardSummary?.totalWorkingClients }} /
                              <span class="font-size-14 fw-normal">{{
                                timeDashboardSummary?.totalAvailableClients
                              }}</span></span
                            >
                          </div>
                          <div class="card-img green-box">
                            <mat-icon fontSet="material-icons-outlined"
                              >work_outline</mat-icon
                            >
                          </div>
                        </div>
                        <div class="card-footer pt-10 hr mt-10">
                          <div class="d-flex align-items-center gap-5">
                            <div
                              class="view-list d-flex align-items-center gap-5"
                            >
                              <div
                                *ngIf="timeDashboardSummary?.clientScore === 0"
                                class="d-flex align-items-center danger"
                              >
                                <mat-icon class="font-size-16"
                                  >south_east</mat-icon
                                >{{
                                  timeDashboardSummary?.totalClientDifference
                                }}
                              </div>
                              <div
                                *ngIf="timeDashboardSummary?.clientScore === 1"
                                class="d-flex align-items-center success"
                              >
                                <mat-icon class="font-size-16"
                                  >north_east</mat-icon
                                >{{
                                  timeDashboardSummary?.totalClientDifference
                                }}
                              </div>
                              <span class="font-size-14">
                                {{ clientSummaryMessage }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card">
                        <div class="card-body">
                          <div class="card-text">
                            <h2 class="font-size-16 fw-normal text-gray pb-5">
                              No.of Tasks Worked on
                            </h2>
                            <span class="font-size-24 fw-bold"
                              >{{ timeDashboardSummary?.totalWorkingTasks }} /
                              <span class="font-size-14 fw-normal">{{
                                timeDashboardSummary.totalAvailableTasks
                              }}</span></span
                            >
                          </div>
                          <div class="card-img red-box">
                            <mat-icon fontSet="material-icons-outlined"
                              >task</mat-icon
                            >
                          </div>
                        </div>
                        <div class="card-footer pt-10 hr mt-10">
                          <div class="d-flex align-items-center gap-5">
                            <div
                              class="view-list d-flex align-items-center gap-5"
                            >
                              <div
                                *ngIf="timeDashboardSummary?.taskScore === 0"
                                class="d-flex align-items-center danger"
                              >
                                <mat-icon class="font-size-16"
                                  >south_east</mat-icon
                                >{{ timeDashboardSummary?.totalTaskDifference }}
                              </div>
                              <div
                                *ngIf="timeDashboardSummary?.taskScore === 1"
                                class="d-flex align-items-center success"
                              >
                                <mat-icon class="font-size-16"
                                  >north_east</mat-icon
                                >{{ timeDashboardSummary?.totalTaskDifference }}
                              </div>
                              <span class="font-size-14">
                                {{ taskSummaryMessage }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card">
                        <div class="card-body">
                          <div class="card-text">
                            <h2 class="font-size-16 fw-normal text-gray pb-5">
                              No.of Users Worked
                            </h2>
                            <span class="font-size-24 fw-bold"
                              >{{ timeDashboardSummary?.totalWorkingUsers }} /
                              <span class="font-size-14 fw-normal">{{
                                timeDashboardSummary.totalAvailableUsers
                              }}</span></span
                            >
                          </div>
                          <div class="card-img blue-box">
                            <mat-icon>people_outline</mat-icon>
                          </div>
                        </div>
                        <div class="card-footer pt-10 hr mt-10">
                          <div class="d-flex align-items-center gap-5">
                            <div
                              class="view-list d-flex align-items-center gap-5"
                            >
                              <div
                                *ngIf="timeDashboardSummary?.userScore === 0"
                                class="d-flex align-items-center danger"
                              >
                                <mat-icon class="font-size-16"
                                  >south_east</mat-icon
                                >{{ timeDashboardSummary?.totalUserDifference }}
                              </div>
                              <div
                                *ngIf="timeDashboardSummary?.userScore === 1"
                                class="d-flex align-items-center success"
                              >
                                <mat-icon class="font-size-16"
                                  >north_east</mat-icon
                                >{{ timeDashboardSummary?.totalUserDifference }}
                              </div>
                              <span class="font-size-14">{{
                                userSummaryMessage
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card">
                        <div class="card-body">
                          <div class="card-text">
                            <h2 class="font-size-16 fw-normal text-gray pb-5">
                              Total Time Spent
                            </h2>
                            <span class="font-size-24 fw-bold"
                              >{{ timeDashboardSummary?.totalTimeSpent }} /
                              <span class="font-size-14 fw-normal">{{
                                timeDashboardSummary?.totalCapacity
                              }}</span></span
                            >
                          </div>
                          <div class="card-img orange-box">
                            <mat-icon>schedule</mat-icon>
                          </div>
                        </div>
                        <div class="card-footer pt-10 hr mt-10">
                          <div class="d-flex align-items-center gap-5">
                            <div
                              class="view-list d-flex align-items-center gap-5"
                            >
                              <div
                                *ngIf="timeDashboardSummary?.timeScore === 0"
                                class="d-flex align-items-center danger"
                              >
                                <mat-icon class="font-size-16"
                                  >south_east</mat-icon
                                >{{ timeDashboardSummary?.totalTimeDifference }}
                              </div>
                              <div
                                *ngIf="timeDashboardSummary?.timeScore === 1"
                                class="d-flex align-items-center success"
                              >
                                <mat-icon class="font-size-16"
                                  >north_east</mat-icon
                                >{{ timeDashboardSummary?.totalTimeDifference }}
                              </div>

                              <span class="font-size-14"
                                >{{ timeSummaryMessage }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div
              *ngIf="
                widget.widgetId === dashboardWidget.MostvsLeastCounts &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">
                        Most vs Least by Profit & Working Hours
                      </div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>

                    <div>
                      <div
                        class="d-flex justify-space-between gap-10 hr-bottom pl-1 pr-1"
                      >
                        <div class="w-100 pt-10 pb-10 hr-right"></div>
                        <div class="wp-100 d-flex justify-space-between gap-10">
                          <div
                            class="wp-50 text-align-center pt-10 pb-10 hr-right fw-bold"
                          >
                            <img
                              src="assets/images/profit.svg"
                              width="15px"
                              class="mr-10"
                              alt="profit"
                            />Profit
                          </div>
                          <div
                            class="wp-50 text-align-center pt-10 pb-10 fw-bold"
                          >
                            <img
                              src="assets/images/hour.svg"
                              width="18px"
                              class="mr-10"
                              alt="hours"
                            />Working Hours
                          </div>
                        </div>
                      </div>
                      <div
                        class="d-flex justify-space-between gap-10 hr-bottom pl-1 pr-1"
                      >
                        <div class="w-100 pt-10 pb-10 hr-right"></div>
                        <div class="wp-100 d-flex justify-space-between gap-10">
                          <div
                            class="wp-25 d-flex align-items-center pt-10 pb-10 hr-right"
                          >
                            <mat-icon
                              fontSet="material-icons-outlined"
                              class="success"
                              >north_east</mat-icon
                            >
                            Most (GBP)
                          </div>
                          <div
                            class="wp-25 d-flex align-items-center pt-10 pb-10 hr-right"
                          >
                            <mat-icon
                              fontSet="material-icons-outlined"
                              class="danger"
                              >south_east</mat-icon
                            >Least (GBP)
                          </div>
                          <div
                            class="wp-25 d-flex align-items-center pt-10 pb-10 hr-right"
                          >
                            <mat-icon
                              fontSet="material-icons-outlined"
                              class="success"
                            >
                              north_east</mat-icon
                            >
                            Most (h)
                          </div>
                          <div
                            class="wp-25 d-flex align-items-center pt-10 pb-10"
                          >
                            <mat-icon
                              fontSet="material-icons-outlined"
                              class="danger"
                              >south_east</mat-icon
                            >Least (h)
                          </div>
                        </div>
                      </div>
                      <div
                        class="d-flex justify-space-between gap-10 hr-bottom pl-1 pr-1"
                      >
                        <div
                          class="w-100 pt-10 pb-10 hr-right d-flex align-items-center gap-5 fw-bold"
                        >
                          <mat-icon
                            fontSet="material-icons-outlined"
                            class="font-size-16"
                            >task</mat-icon
                          >
                          Task
                        </div>
                        <div class="wp-100 d-flex justify-space-between gap-10">
                          <div class="wp-25 pt-10 pb-10 hr-right">
                            <div
                              class="d-flex justify-space-between gap-10 pr-10"
                            >
                              <div>
                                {{ mostLeastCount.mostProfitableTaskName }}
                              </div>
                              <div
                                *ngIf="
                                  mostLeastCount.mostProfitableTaskAmount !==
                                  null
                                "
                                class="fw-bold"
                                [ngClass]="
                                  mostLeastCount.mostProfitableTaskAmount < 0
                                    ? 'danger'
                                    : ''
                                "
                              >
                                £
                                {{
                                  mostLeastCount.mostProfitableTaskAmount
                                    | number : "1.2-2"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="wp-25 pt-10 pb-10 hr-right">
                            <div
                              class="d-flex justify-space-between gap-10 pr-10"
                            >
                              <div>
                                {{ mostLeastCount.leastProfitableTaskName }}
                              </div>
                              <div
                                *ngIf="
                                  mostLeastCount.leastProfitableTaskAmount !==
                                  null
                                "
                                class="fw-bold"
                                [ngClass]="
                                  mostLeastCount.leastProfitableTaskAmount < 0
                                    ? 'danger'
                                    : ''
                                "
                              >
                                £
                                {{
                                  mostLeastCount.leastProfitableTaskAmount
                                    | number : "1.2-2"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="wp-25 pt-10 pb-10 hr-right">
                            <div
                              class="d-flex justify-space-between gap-10 pr-10"
                            >
                              <div>
                                {{ mostLeastCount.mostTimeTakingTaskName }}
                              </div>
                              <div
                                *ngIf="
                                  mostLeastCount.mostTimeTakingTaskHours !==
                                  null
                                "
                                class="fw-bold"
                                [ngClass]="
                                  mostLeastCount.mostTimeTakingTaskHours.includes(
                                    '-'
                                  )
                                    ? 'danger'
                                    : ''
                                "
                              >
                                {{ mostLeastCount.mostTimeTakingTaskHours }} h
                              </div>
                            </div>
                          </div>
                          <div class="wp-25 pt-10 pb-10">
                            <div
                              class="d-flex justify-space-between gap-10 pr-10"
                            >
                              <div>
                                {{ mostLeastCount.leastTimeTakingTaskName }}
                              </div>
                              <div
                                *ngIf="
                                  mostLeastCount.leastTimeTakingTaskHours !==
                                  null
                                "
                                class="fw-bold"
                                [ngClass]="
                                  mostLeastCount.leastTimeTakingTaskHours.includes(
                                    '-'
                                  )
                                    ? 'danger'
                                    : ''
                                "
                              >
                                {{ mostLeastCount.leastTimeTakingTaskHours }} h
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="d-flex justify-space-between gap-10 hr-bottom pl-1 pr-1"
                      >
                        <div
                          class="w-100 pt-10 pb-10 hr-right d-flex align-items-center gap-5 fw-bold"
                        >
                          <mat-icon
                            fontSet="material-icons-outlined"
                            class="font-size-16"
                            >work_outline</mat-icon
                          >
                          Client
                        </div>
                        <div class="wp-100 d-flex justify-space-between gap-10">
                          <div class="wp-25 pt-10 pb-10 hr-right">
                            <div
                              class="d-flex justify-space-between gap-10 pr-10"
                            >
                              <div>
                                {{ mostLeastCount.mostProfitableClientName }}
                              </div>
                              <div
                                *ngIf="
                                  mostLeastCount.mostProfitableClientAmount !==
                                  null
                                "
                                class="fw-bold"
                                [ngClass]="
                                  mostLeastCount.mostProfitableClientAmount < 0
                                    ? 'danger'
                                    : ''
                                "
                              >
                                £
                                {{
                                  mostLeastCount.mostProfitableClientAmount
                                    | number : "1.2-2"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="wp-25 pt-10 pb-10 hr-right">
                            <div
                              class="d-flex justify-space-between gap-10 pr-10"
                            >
                              <div>
                                {{ mostLeastCount.leastProfitableClientName }}
                              </div>
                              <div
                                *ngIf="
                                  mostLeastCount.leastProfitableClientAmount !==
                                  null
                                "
                                class="fw-bold"
                                [ngClass]="
                                  mostLeastCount.leastProfitableClientAmount < 0
                                    ? 'danger'
                                    : ''
                                "
                              >
                                £
                                {{
                                  mostLeastCount.leastProfitableClientAmount
                                    | number : "1.2-2"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="wp-25 pt-10 pb-10 hr-right">
                            <div
                              class="d-flex justify-space-between gap-10 pr-10"
                            >
                              <div>
                                {{ mostLeastCount.mostTimeTakingClientName }}
                              </div>
                              <div
                                *ngIf="
                                  mostLeastCount.mostTimeTakingClientHours !==
                                  null
                                "
                                class="fw-bold"
                                [ngClass]="
                                  mostLeastCount.mostTimeTakingClientHours.includes(
                                    '-'
                                  )
                                    ? 'danger'
                                    : ''
                                "
                              >
                                {{ mostLeastCount.mostTimeTakingClientHours }} h
                              </div>
                            </div>
                          </div>
                          <div class="wp-25 pt-10 pb-10">
                            <div
                              class="d-flex justify-space-between gap-10 pr-10"
                            >
                              <div>
                                {{ mostLeastCount.leastTimeTakingClientName }}
                              </div>
                              <div
                                *ngIf="
                                  mostLeastCount.leastTimeTakingClientHours !==
                                  null
                                "
                                class="fw-bold"
                                [ngClass]="
                                  mostLeastCount.leastTimeTakingClientHours.includes(
                                    '-'
                                  )
                                    ? 'danger'
                                    : ''
                                "
                              >
                                {{ mostLeastCount.leastTimeTakingClientHours }}
                                h
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="d-flex justify-space-between gap-10 pl-1 pr-1"
                      >
                        <div
                          class="w-100 pt-10 pb-10 hr-right d-flex align-items-center gap-5 fw-bold"
                        >
                          <mat-icon
                            fontSet="material-icons-outlined"
                            class="font-size-16"
                            >people_outline</mat-icon
                          >
                          User
                        </div>
                        <div class="wp-100 d-flex justify-space-between gap-10">
                          <div class="wp-25 pt-10 pb-10 hr-right">
                            <div
                              class="d-flex justify-space-between gap-10 pr-10"
                            >
                              <div>
                                {{ mostLeastCount.mostWorkingUserNameByAmount }}
                              </div>
                              <div
                                *ngIf="
                                  mostLeastCount.mostWorkingUserByAmount !==
                                  null
                                "
                                class="fw-bold"
                                [ngClass]="
                                  mostLeastCount.mostWorkingUserByAmount < 0
                                    ? 'danger'
                                    : ''
                                "
                              >
                                £
                                {{
                                  mostLeastCount.mostWorkingUserByAmount
                                    | number : "1.2-2"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="wp-25 pt-10 pb-10 hr-right">
                            <div
                              class="d-flex justify-space-between gap-10 pr-10"
                            >
                              <div>
                                {{
                                  mostLeastCount.leastWorkingUserNameByAmount
                                }}
                              </div>
                              <div
                                *ngIf="
                                  mostLeastCount.leastWorkingUserByAmount !==
                                  null
                                "
                                class="fw-bold"
                                [ngClass]="
                                  mostLeastCount.leastWorkingUserByAmount < 0
                                    ? 'danger'
                                    : ''
                                "
                              >
                                £
                                {{
                                  mostLeastCount.leastWorkingUserByAmount
                                    | number : "1.2-2"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="wp-25 pt-10 pb-10 hr-right">
                            <div
                              class="d-flex justify-space-between gap-10 pr-10"
                            >
                              <div>
                                {{ mostLeastCount.mostWorkingUserNameByTime }}
                              </div>
                              <div
                                *ngIf="
                                  mostLeastCount.mostWorkingUserHours !== null
                                "
                                class="fw-bold"
                                [ngClass]="
                                  mostLeastCount.mostWorkingUserHours.includes(
                                    '-'
                                  )
                                    ? 'danger'
                                    : ''
                                "
                              >
                                {{ mostLeastCount.mostWorkingUserHours }} h
                              </div>
                            </div>
                          </div>
                          <div class="wp-25 pt-10 pb-10">
                            <div
                              class="d-flex justify-space-between gap-10 pr-10"
                            >
                              <div>
                                {{ mostLeastCount.leastWorkingUserNameByTime }}
                              </div>
                              <div
                                *ngIf="
                                  mostLeastCount.leastWorkingUserHours !== null
                                "
                                class="fw-bold"
                                [ngClass]="
                                  mostLeastCount.leastWorkingUserHours.includes(
                                    '-'
                                  )
                                    ? 'danger'
                                    : ''
                                "
                              >
                                {{ mostLeastCount.leastWorkingUserHours }} h
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div
              *ngIf="
                widget.widgetId === dashboardWidget.TaskWiseHoursDetails &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between pt-5 pb-5"
                    >
                      <div class="dash-title">Task Wise Hours Details</div>
                      <div
                        class="dash-dropdown-list d-flex align-items-center gap-15"
                      >
                        <ng-select
                          class="w-170"
                          #task
                          autoFocus
                          [(ngModel)]="taskIdForHoursDetails"
                          panelClass="myPanelClass"
                          [disableOptionCentering]="true"
                          appearance="fill"
                          (change)="onSelectionForTaskWiseHours($event)"
                        >
                          <ng-option [value]="defaultGuid">Tasks</ng-option>
                          <ng-option
                            *ngFor="let tl of taskList"
                            [value]="tl.universalId"
                            >{{ tl.name }}</ng-option
                          >
                        </ng-select>
                        <span
                          id="close"
                          class="e-template-icon e-close-icon"
                          (click)="lockDashboard ? '' : onCloseIcon(widget)"
                        >
                          <mat-icon class="cursor-pointer">close</mat-icon>
                        </span>
                      </div>
                    </div>
                    <div class="dash-body pb-0">
                      <app-task-weekly-hours
                        [triggerFilterData]="triggerFilterData"
                        [triggerTaskIdForHoursDetails]="
                          triggerTaskIdForHoursDetails
                        "
                      ></app-task-weekly-hours>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div
              *ngIf="
                widget.widgetId === dashboardWidget.TimerWidget &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">Timer</div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="dash-body text-align-center pt-2 pb-2">
                      <div class="text-align-center">
                        <img
                          src="assets/images/timer-img.svg"
                          alt="Timer"
                          width="70px"
                        />
                      </div>
                      <div class="wp-100 mt-2 mb-5">
                        <div class="form-field">
                          <ng-select
                            [(ngModel)]="jobId"
                            class="w-200 m-auto"
                            panelClass="myPanelClass"
                            [disableOptionCentering]="true"
                            (change)="onJobSelectionChange($event)"
                            appearance="fill"
                          >
                            <ng-option [value]="defaultGuid">Jobs</ng-option>
                            <ng-option
                              *ngFor="let job of jobList"
                              [value]="job.universalId"
                              >{{ job.jobCode }}</ng-option
                            >
                          </ng-select>
                        </div>

                        <button
                          class="action-button secondary-button timer-icon"
                          mat-dialog-close
                          disableRipple
                          type="button"
                          mat-button
                          disableRipple
                          (click)="onStartTimerClick()"
                        >
                          <mat-icon fontSet="material-icons-outlined"
                            >timer</mat-icon
                          >
                          Start Timer
                        </button>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div
              *ngIf="
                widget.widgetId === dashboardWidget.OverallHoursDetails &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between pt-5 pb-5"
                    >
                      <div class="dash-title">Total Task Hours</div>
                      <div
                        class="dash-dropdown-list d-flex align-items-center gap-15"
                      >
                        <ng-select
                          class="w-170"
                          #task
                          autoFocus
                          [(ngModel)]="taskId"
                          panelClass="myPanelClass"
                          [disableOptionCentering]="true"
                          appearance="fill"
                          (change)="onTaskSelectionChange($event)"
                        >
                          <ng-option [value]="defaultGuid">Tasks</ng-option>
                          <ng-option
                            *ngFor="let tl of taskList"
                            [value]="tl.universalId"
                            >{{ tl.name }}</ng-option
                          >
                        </ng-select>
                        <span
                          id="close"
                          class="e-template-icon e-close-icon"
                          (click)="lockDashboard ? '' : onCloseIcon(widget)"
                        >
                          <mat-icon class="cursor-pointer">close</mat-icon>
                        </span>
                      </div>
                    </div>
                    <div class="dash-body p-0">
                      <app-task-overall-hours
                        [triggerFilterData]="triggerFilterData"
                        [triggerTaskId]="triggerTaskId"
                      ></app-task-overall-hours>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div
              *ngIf="
                widget.widgetId === dashboardWidget.BillableNonBillableHours &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">
                        Billable & Non-billable Hours
                      </div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="dash-body p-0">
                      <app-task-billable-hours
                        [triggerFilterData]="triggerFilterData"
                      ></app-task-billable-hours>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div
              *ngIf="
                widget.widgetId === dashboardWidget.TimeoffByUser &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">Time off Hours by Users</div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="dash-body">
                      <app-task-time-byuser
                        [triggerFilterData]="triggerFilterData"
                      >
                      </app-task-time-byuser>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div
              *ngIf="
                widget.widgetId === dashboardWidget.JobCounts && widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">Jobs Count by Status</div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="dash-body p-0">
                      <div class="custom-table">
                        <table aria-describedby="Jobs Count by Status">
                          <tr>
                            <th>&nbsp;</th>
                            <th>
                              <div
                                class="d-flex align-items-center justify-content-center fw-normal gap-10"
                              >
                                <img
                                  src="assets/images/timeandfees.svg"
                                  width="14px"
                                  alt="Time and Fees"
                                />
                                Time And <br />
                                Fees
                              </div>
                            </th>
                            <th>
                              <div
                                class="d-flex align-items-center justify-content-center fw-normal gap-10"
                              >
                                <img
                                  src="assets/images/fixedfees.svg"
                                  width="14px"
                                  alt="Fixed Price"
                                />
                                Fixed <br />
                                Fees
                              </div>
                            </th>
                            <th>
                              <div
                                class="d-flex align-items-center justify-content-center fw-normal gap-10"
                              >
                                <img
                                  src="assets/images/nonbillable.svg"
                                  width="14px"
                                  alt="Non Billable"
                                />
                                Non- <br />Billable
                              </div>
                            </th>
                          </tr>

                          <tr>
                            <td>
                              <div class="count-status blue-box">
                                <span></span>{{ jobCount[0].statusName }}
                              </div>
                            </td>
                            <td>{{ jobCount[0]?.timeandFees }}</td>
                            <td>{{ jobCount[0]?.fixedPrice }}</td>
                            <td>{{ jobCount[0]?.nonBillable }}</td>
                          </tr>
                          <tr>
                            <td>
                              <div class="count-status orange-box">
                                <span></span>{{ jobCount[1]?.statusName }}
                              </div>
                            </td>
                            <td>{{ jobCount[1]?.timeandFees }}</td>
                            <td>{{ jobCount[1]?.fixedPrice }}</td>
                            <td>{{ jobCount[1]?.nonBillable }}</td>
                          </tr>
                          <tr>
                            <td>
                              <div class="count-status green-box">
                                <span></span>{{ jobCount[2]?.statusName }}
                              </div>
                            </td>
                            <td>{{ jobCount[2]?.timeandFees }}</td>
                            <td>{{ jobCount[2]?.fixedPrice }}</td>
                            <td>{{ jobCount[2]?.nonBillable }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div
              *ngIf="
                widget.widgetId === dashboardWidget.FeesSummary &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">Fees Summary</div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="card-wrapper p-1">
                      <div class="card">
                        <div class="card-body">
                          <div class="card-text">
                            <h2 class="font-size-16 fw-normal text-gray pb-5">
                              Total Invoiced (GBP)
                            </h2>
                            <span
                              *ngIf="
                                feesDashboardSummary?.totalInvoiceAmount !==
                                null
                              "
                            >
                              £
                              {{
                                feesDashboardSummary?.totalInvoiceAmount
                                  | number : "1.2-2"
                              }}</span
                            >
                          </div>
                          <div class="card-img green-box">
                            <mat-icon fontSet="material-icons-outlined"
                              >update</mat-icon
                            >
                          </div>
                        </div>
                        <div class="card-footer pt-10 hr mt-10">
                          <div class="d-flex align-items-center gap-5">
                            <div
                              [ngClass]="
                                feesDashboardSummary?.totalInvoiceScore === 0
                                  ? 'danger'
                                  : 'success'
                              "
                              class="view-list d-flex align-items-center gap-5"
                            >
                              <mat-icon
                                class="font-size-16"
                                *ngIf="
                                  feesDashboardSummary?.totalInvoiceScore === 0
                                "
                                >south_east</mat-icon
                              >
                              <mat-icon
                                class="font-size-16"
                                *ngIf="
                                  feesDashboardSummary?.totalInvoiceScore === 1
                                "
                                >north_east</mat-icon
                              >
                              <span class="font-size-14">
                                £
                                {{
                                  feesDashboardSummary?.totalInvoiceAmountDifference
                                    | number : "1.2-2"
                                }}</span
                              >
                            </div>
                            <div>{{ totalInvoiceSummaryMessage }}</div>
                          </div>
                        </div>
                      </div>

                      <div class="card">
                        <div class="card-body">
                          <div class="card-text">
                            <h2 class="font-size-16 fw-normal text-gray pb-5">
                              Paid Amount (GBP)
                            </h2>
                            <span
                              *ngIf="
                                feesDashboardSummary?.totalPaidInvoiceAmount !==
                                null
                              "
                            >
                              £
                              {{
                                feesDashboardSummary?.totalPaidInvoiceAmount
                                  | number : "1.2-2"
                              }}</span
                            >
                          </div>
                          <div class="card-img red-box">
                            <mat-icon>currency_pound</mat-icon>
                          </div>
                        </div>
                        <div class="card-footer pt-10 hr mt-10">
                          <div class="d-flex align-items-center gap-5">
                            <div
                              [ngClass]="
                                feesDashboardSummary?.totalPaidInvoiceScore ===
                                0
                                  ? 'danger'
                                  : 'success'
                              "
                              class="view-list d-flex align-items-center gap-5"
                            >
                              <mat-icon
                                class="font-size-16"
                                *ngIf="
                                  feesDashboardSummary?.totalPaidInvoiceScore ===
                                  0
                                "
                                >south_east</mat-icon
                              >
                              <mat-icon
                                class="font-size-16"
                                *ngIf="
                                  feesDashboardSummary?.totalPaidInvoiceScore ===
                                  1
                                "
                                >north_east</mat-icon
                              >
                              <span class="font-size-14">
                                £
                                {{
                                  feesDashboardSummary?.totalPaidInvoiceAmountDifference
                                    | number : "1.2-2"
                                }}</span
                              >
                            </div>
                            <div>{{ paidAmountSummaryMessage }}</div>
                          </div>
                        </div>
                      </div>

                      <div class="card">
                        <div class="card-body">
                          <div class="card-text">
                            <h2 class="font-size-16 fw-normal text-gray pb-5">
                              Unpaid Amount (GBP)
                            </h2>
                            <span
                              *ngIf="
                                feesDashboardSummary?.totalUnPaidInvoiceAmount !==
                                null
                              "
                            >
                              £
                              {{
                                feesDashboardSummary?.totalUnPaidInvoiceAmount
                                  | number : "1.2-2"
                              }}</span
                            >
                          </div>
                          <div class="card-img blue-box">
                            <mat-icon>people_outline</mat-icon>
                          </div>
                        </div>
                        <div class="card-footer pt-10 hr mt-10">
                          <div class="d-flex align-items-center gap-5">
                            <div
                              [ngClass]="
                                feesDashboardSummary?.totalUnPaidInvoiceScore ===
                                0
                                  ? 'danger'
                                  : 'success'
                              "
                              class="view-list d-flex align-items-center gap-5"
                            >
                              <mat-icon
                                class="font-size-16"
                                *ngIf="
                                  feesDashboardSummary?.totalUnPaidInvoiceScore ===
                                  0
                                "
                                >south_east</mat-icon
                              >
                              <mat-icon
                                class="font-size-16"
                                *ngIf="
                                  feesDashboardSummary?.totalUnPaidInvoiceScore ===
                                  1
                                "
                                >north_east</mat-icon
                              >
                              <span class="font-size-14">
                                £
                                {{
                                  feesDashboardSummary?.totalUnPaidInvoiceAmountDifference
                                    | number : "1.2-2"
                                }}</span
                              >
                            </div>
                            <div>{{ unpaidAmountsummaryMessage }}</div>
                          </div>
                        </div>
                      </div>

                      <div class="card">
                        <div class="card-body">
                          <div class="card-text">
                            <h2 class="font-size-16 fw-normal text-gray pb-5">
                              Overdue Amount (GBP)
                            </h2>
                            <span
                              *ngIf="
                                feesDashboardSummary?.totalOverdueAmount !==
                                null
                              "
                            >
                              £
                              {{
                                feesDashboardSummary?.totalOverdueAmount
                                  | number : "1.2-2"
                              }}</span
                            >
                          </div>
                          <div class="card-img orange-box">
                            <mat-icon>schedule</mat-icon>
                          </div>
                        </div>
                        <div class="card-footer pt-10 hr mt-10">
                          <div class="d-flex align-items-center gap-5">
                            <div
                              [ngClass]="
                                feesDashboardSummary?.totalOverdueScore === 0
                                  ? 'danger'
                                  : 'success'
                              "
                              class="view-list d-flex align-items-center gap-5"
                            >
                              <mat-icon
                                class="font-size-16"
                                *ngIf="
                                  feesDashboardSummary?.totalOverdueScore === 0
                                "
                                >south_east</mat-icon
                              >
                              <mat-icon
                                class="font-size-16"
                                *ngIf="
                                  feesDashboardSummary?.totalOverdueScore === 1
                                "
                                >north_east</mat-icon
                              >
                              <span class="font-size-14">
                                £
                                {{
                                  feesDashboardSummary?.totalOverdueAmountDifference
                                    | number : "1.2-2"
                                }}</span
                              >
                            </div>
                            <div>{{ overdueAmountSummaryMessage }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div
              *ngIf="
                widget.widgetId === dashboardWidget.IncomeTrend &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">Income Trend</div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="dash-body p-0">
                      <app-fees-income-trend
                        [triggerFilterData]="triggerFilterData"
                      >
                      </app-fees-income-trend>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div
              *ngIf="
                widget.widgetId === dashboardWidget.RevenueFlow &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">Revenue Flow</div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="dash-body p-0">
                      <app-fees-revenue-flow
                        [triggerFilterData]="triggerFilterData"
                      ></app-fees-revenue-flow>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div
              *ngIf="
                widget.widgetId === dashboardWidget.InvoiceAmountByCategory &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">By Invoiced Category Amount</div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="dash-body p-0">
                      <app-fees-invoice-category
                        [triggerFilterData]="triggerFilterData"
                      ></app-fees-invoice-category>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div
              *ngIf="
                widget.widgetId === dashboardWidget.TopClientsByInvoiceAmount &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">
                        Top 5 Clients by Invoiced Amount
                      </div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="dash-body p-0">
                      <app-fees-invoice-amount
                        [triggerFilterData]="triggerFilterData"
                      ></app-fees-invoice-amount>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div
              *ngIf="
                widget.widgetId === dashboardWidget.TopClientsWithBalance &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">
                        Top 5 Clients by the Amount Due
                      </div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="dash-body p-0">
                      <app-fees-client-balance
                        [triggerFilterData]="triggerFilterData"
                      ></app-fees-client-balance>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div
              *ngIf="
                widget.widgetId === dashboardWidget.PaymentMethods &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">
                        Amount Received by Payment Methods
                      </div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="dash-body p-0">
                      <app-fees-payment-methods
                        [triggerFilterData]="triggerFilterData"
                      ></app-fees-payment-methods>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div
              *ngIf="
                widget.widgetId === dashboardWidget.EstimatesStatus &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">
                        Estimates by Status and Amount
                      </div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="dash-body p-0">
                      <app-fees-estimate-status
                        [triggerFilterData]="triggerFilterData"
                      ></app-fees-estimate-status>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div
              *ngIf="
                widget.widgetId === dashboardWidget.InvoiceStatus &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">Invoiced Amount by Status</div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="dash-body p-0">
                      <app-fees-invoice-status
                        [triggerFilterData]="triggerFilterData"
                      ></app-fees-invoice-status>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div
              *ngIf="
                widget.widgetId ===
                  dashboardWidget.InvoiceAmountByInvoiceMonth && widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">By Invoiced Amount</div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="dash-body p-0">
                      <app-fees-invoice-month
                        [triggerFilterData]="triggerFilterData"
                      ></app-fees-invoice-month>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div
              *ngIf="
                widget.widgetId === dashboardWidget.BalanceAmountByDueMonth &&
                widget.isEnable
              "
              cdkDrag
              (cdkDragEntered)="entered($event)"
              [cdkDragData]="i"
              class="dashboard-card"
              [cdkDragDisabled]="lockDashboard"
            >
              <mat-card>
                <mat-card-content class="dashboard-card-content">
                  <div class="card p-0">
                    <div
                      class="dash-heading hr-bottom d-flex align-items-center justify-space-between"
                    >
                      <div class="dash-title">By Due Amount</div>
                      <span
                        id="close"
                        class="e-template-icon e-close-icon"
                        (click)="lockDashboard ? '' : onCloseIcon(widget)"
                      >
                        <mat-icon class="cursor-pointer">close</mat-icon>
                      </span>
                    </div>
                    <div class="dash-body p-0">
                      <app-fees-due-month
                        [triggerFilterData]="triggerFilterData"
                      ></app-fees-due-month>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </cdk-drop-list>
        </div>
      </div>
    </div>
  </div>
</div>
