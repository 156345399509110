export const environment = {
  production: true,
  apiUrl: 'https://timeandfeesapi.beta.capium.co.uk/',
  uiUrl: 'https://timeandfees.beta.capium.co.uk/',
  stsServerUrl: 'https://identity.beta.capium.co.uk',
  identityLoginUrl: 'https://identity.beta.capium.co.uk/Account/Login',
  appDomain: 'timeandfees.beta',
  clientId: 'Capium_Internal_Apps',
  cdnUrl: 'https://cdn.beta.capium.co.uk/shared/',
  communicationFrameworkApiUrl:
    'https://communicationframeworkapi.beta.capium.co.uk/api',
  apiVersionUrl: 'https://timeandfeesapi.beta.capium.co.uk/api/v1',
  helpDeskUrl: 'https://capium.freshdesk.com/support/home',
};
