import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import {
  CommonNotificationText,
  JobStatusModel,
  Modules,
  NotificationTextMessage,
  TimelogStatus,
} from '@app/core/enums';
import {
  GlobalComponent,
  JobSummaryModel,
  MainListParameters,
  UpdateJobStatusModel,
} from '@app/core/models';
import { CommonService } from '@app/core/services';
import { AddClosePopupComponent } from '@app/core/shared/components';
import { SettingsState } from '@app/core/store';
import {
  GetJobDetailByUniversalId,
  GetJobSummaryByUniversalId,
  JobState,
  SetJobId,
  UpdateJobStatus,
} from '@app/core/store/job';
import { Select, Store } from '@ngxs/store';
import { Buffer } from 'buffer';
import { Guid } from 'guid-typescript';
import {
  ApexChart,
  ApexFill,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ChartComponent,
} from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AddTimelogComponent } from '../../time/add-timelog/add-timelog.component';
import { JobDetailsSubtaskComponent } from './job-details-subtask/job-details-subtask.component';
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  fill: ApexFill;
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss'],
})
export class JobDetailsComponent implements OnInit {
  @Select(SettingsState.getSubMenuChildren)
  subMenusChildren$: Observable<any>;

  moduleId = Modules.Jobs;
  isHideSideList = false;
  istoggleSideList = true;
  isShowSideListAction = false;
  showOverview = false;
  isRecurringExist = false;
  subscriptionRouting: Subscription;
  jobId = Guid.EMPTY as unknown as Guid;
  headerText = '';
  jobDetailText = '';
  listParameters: MainListParameters = new MainListParameters();
  detailArray: any;
  formDetails: UntypedFormGroup;
  tableDataSource: MatTableDataSource<AbstractControl>;
  jobSummaryData: JobSummaryModel;
  selectedIndex = 0;
  jobStatusModel = JobStatusModel;
  triggerJobId: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  triggerJobTimelogs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  triggerJobTimelogData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  triggerJobInvoiceData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  modules = Modules;
  selectedStatus = TimelogStatus.Unsubmitted;
  isEndDateGreaterCurrentDate: boolean = false;
  triggerTimelogOverviewData: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  triggerJobSubtaskData: Subject<any> = new Subject<any>();

  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  @Select(JobState.getJobDetailByUniversalId)
  jobData$: Observable<Array<any>>;

  @Select(SettingsState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  @ViewChild(JobDetailsSubtaskComponent, { static: false })
  jobDetailsSubtaskDetails;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @Output()
  readonly triggerForList = new EventEmitter<any>();

  filesCount: number = 0;
  commentCount: number = 0;
  emailCount: number = 0;
  activityCount: number = 0;
  updateJobStatusModel: UpdateJobStatusModel;
  userId = this.globalComponent.getLoggedInUserId();
  commonNotificationText = CommonNotificationText;

  constructor(
    public dialog: MatDialog,
    private _Activatedroute: ActivatedRoute,
    private store: Store,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private globalComponent: GlobalComponent
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        this.selectedIndex = 0;
        if (params.keys.length > 0) {
          this.jobId = Buffer.from(
            params.get('id')!,
            'base64'
          ).toString() as unknown as Guid;
          this.store.dispatch(new SetJobId(this.jobId));
          this.triggerJobId.next(this.jobId);
          this.showOverview = false;
          this.editJob();
        }
      }
    );
  }

  updateStatus(status: number): void {
    let ids: any = [];
    ids.push(this.jobId);
    this.updateJobStatusModel = {
      universalIds: ids,
      status: status,
    };
    this.store
      .dispatch(new UpdateJobStatus(this.updateJobStatusModel))
      .pipe()
      .subscribe((res) => {
        if (res.job.isStatusUpdated) {
          this.commonService.onSuccess(NotificationTextMessage.successMessage);
          this.editJob();
        } else {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      });
  }

  triggerFilesCount(value: number): void {
    this.filesCount = value;
  }

  triggerCommentCount(value: number): void {
    this.commentCount = value;
  }

  triggerEmailCount(value: number): void {
    this.emailCount = value;
  }

  triggerIsRecurringExist(value: boolean): void {
    this.isRecurringExist = value;
  }

  triggerActivityCountToTab(value: number): void {
    this.activityCount = value;
  }

  editJob(): void {
    this.spinner.show();
    this.getJobDetailByUniversalId();

    this.store
      .dispatch(new GetJobSummaryByUniversalId(this.jobId))
      .subscribe((res) => {
        this.headerText = `Job Details - ${res.job.jobSummaryData.jobCode}`;
        this.jobDetailText = `${res.job.jobSummaryData.clientName} - ${res.job.jobSummaryData.taskName}`;

        this.jobSummaryData = res.job.jobSummaryData;
        this.isEndDateGreaterCurrentDate =
          new Date(this.jobSummaryData?.endDate!) > new Date();
        this.bindChartData();
      });
  }

  bindChartData(): void {
    this.chartOptions = {
      series: [this.jobSummaryData.score!],
      chart: {
        height: 110,
        width: 110,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '50%',
          },
          track: {
            background: '#D2D2D2',
          },

          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              offsetY: 5,
              fontSize: '13px',
              color: '#6661B8',
              fontWeight: 600,
            },
            total: {
              show: false,
            },
          },
        },
      },
      fill: {
        type: 'solid',
        colors: ['#41DA73'],
      },
      labels: [''],
    };
  }

  showSideListAction(val: any): void {
    this.isShowSideListAction = val;

    if (!val) {
      this.editJob();
    }
  }

  toggleSideList(): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {
      data: {
        moduleId: this.moduleId,
      },
    });
  }

  openTimelogDialog() {
    const dialogRef = this.dialog.open(AddTimelogComponent, {
      data: {
        moduleId: Modules.Jobs,
        jobId: this.jobId,
        isCalendarView: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.triggerJobTimelogs.next(true);
        this.ngOnInit();
      }
    });
  }

  overviewClick(event: any) {
    this.showOverview = true;
    this.selectedStatus = event.status;
    this.triggerTimelogOverviewData.next(event.timelogData);
  }

  getJobDetailByUniversalId(): void {
    this.spinner.show();
    if (this.jobId) {
      this.store
        .dispatch(new GetJobDetailByUniversalId(this.jobId))
        .subscribe((res) => {
          this.triggerJobSubtaskData.next(res.job.jobData);
        });
    }
  }

  closeClick() {
    this.showOverview = false;
    this.selectedIndex = 0;
  }

  tabClick(tab) {
    window.dispatchEvent(new Event('resize'));

    let selectedOrdinal: number = Modules.JobDetail;
    this.selectedIndex = tab.index;
    window.dispatchEvent(new Event('resize'));

    this.subMenusChildren$
      .pipe(
        take(1),
        map((subMenus) => subMenus[this.selectedIndex])
      )
      .subscribe((selectedSubMenu) => {
        selectedOrdinal = selectedSubMenu?.ordinal;
      });

    switch (selectedOrdinal) {
      case Modules.JobTimelogs:
        this.triggerJobTimelogData.next(this.jobId);
        break;

      case Modules.JobInvoice:
        this.triggerJobInvoiceData.next(this.jobId);
        break;

      default:
        break;
    }
  }

  startTimer(): void {
    const selectedUserIds = this.jobDetailsSubtaskDetails.userList
      .filter((x) => x.isAssigned === true)
      .map((x) => x.userId);

    const filterData = selectedUserIds.filter((x) => x === this.userId);

    if (filterData.length > 0) {
      let data = {
        clientId: this.jobSummaryData.clientId,
        clientName: this.jobSummaryData.clientName,
        jobId: this.jobSummaryData.universalId,
        jobCode: this.jobSummaryData.jobCode,
        taskId: this.jobSummaryData.taskId,
        taskName: this.jobSummaryData.taskName,
      };

      const timerData = {
        showTimer: true,
        jobData: data,
      };
      this.commonService.showTimerPopup(timerData);
    } else {
      this.commonService.onFailure(NotificationTextMessage.assignUserMessage);
    }
  }
}
