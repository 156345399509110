export class UserWidgetModel {
  widgetId: number;
  widgetTypeName: string;
  widgetTypeId: number;
  widgetName: string;
  isEnable: boolean;
  sortOrder: number;
  iconName: string;
}

export class TimeDashboardSummary {
  totalAvailableClients: number;
  totalWorkingClients: number;
  clientScore: number;
  totalClientDifference: number;
  totalAvailableTasks: number;
  totalWorkingTasks: number;
  taskScore: number;
  totalTaskDifference: number;
  totalAvailableUsers: number;
  totalWorkingUsers: number;
  userScore: number;
  totalUserDifference: number;
  totalCapacity: string;
  totalTimeSpent: string;
  timeScore: number;
  totalTimeDifference: string;
}

export class MostLeastCountModel {
  leastTimeTakingTaskName: string;
  leastTimeTakingTaskHours: string;
  mostTimeTakingTaskName: string;
  mostTimeTakingTaskHours: string;
  leastProfitableClientName: string;
  leastProfitableClientAmount: number;
  mostProfitableClientName: string;
  mostProfitableClientAmount: number;
  leastWorkingUserNameByTime: string;
  leastWorkingUserHours: string;
  mostWorkingUserNameByTime: string;
  mostWorkingUserHours: string;
  leastWorkingUserNameByAmount: number;
  leastWorkingUserByAmount: number;
  mostWorkingUserNameByAmount: number;
  mostWorkingUserByAmount: number;
  mostProfitableTaskName: string;
  mostProfitableTaskAmount: number;
  leastProfitableTaskName: string;
  leastProfitableTaskAmount: number;
  mostTimeTakingClientName: string;
  mostTimeTakingClientHours: string;
  leastTimeTakingClientName: string;
  leastTimeTakingClientHours: string;
}
