<div class="mat-dialog-wrapper ticket-popup">
  <mat-dialog-content>
    <form [formGroup]="supportTicketForm">
      <div class="main-contain">
        <div class="sidenav-header">
          <div class="d-flex align-items-center justify-space-between">
            <h2 class="m-0">
              <span>New Support Ticket</span>
            </h2>
            <div mat-dialog-close>
              <mat-icon class="close">close</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="sidenav-body">
        <div class="d-flex gap-20">
          <div class="form-field w-230">
            <p class="fw-bold">Name <span class="text-red">*</span></p>
            <mat-form-field appearance="fill" class="light-bg">
              <input
                type="text"
                matInput
                maxlength="255"
                autocomplete="off"
                formControlName="name"
                required
                [readonly]="true"
              />
            </mat-form-field>
          </div>
          <div class="form-field w-230">
            <p class="fw-bold">Email <span class="text-red">*</span></p>
            <mat-form-field appearance="fill" class="light-bg">
              <input
                matInput
                required
                autocomplete="off"
                formControlName="email"
                maxlength="320"
                [readonly]="true"
              />
            </mat-form-field>
          </div>
          <div class="form-field w-230">
            <p class="fw-bold">Phone <span class="text-red">*</span></p>
            <mat-form-field appearance="fill">
              <input
                matInput
                required
                autocomplete="off"
                type="tel"
                minlength="0"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                maxlength="15"
                formControlName="phone"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex gap-20">
          <div class="form-field w-230">
            <p class="fw-bold">Company</p>
            <ng-select
              #client
              (focus)="client.open()"
              (valueChange)="client.close()"
              formControlName="client"
              panelClass="myPanelClass"
              [disableOptionCentering]="true"
              appearance="fill"
              required
            >
              <ng-option
                *ngFor="let client of clientList"
                [value]="client.name"
                >{{ client.name }}</ng-option
              >
            </ng-select>
          </div>
          <div class="form-field w-230">
            <p class="fw-bold">Help Topic</p>
            <ng-select
              #support
              (focus)="support.open()"
              (valueChange)="support.close()"
              panelClass="myPanelClass"
              [disableOptionCentering]="true"
              formControlName="helpTopic"
              [readonly]="true"
            >
              <ng-option [value]="9000140263">Support Group</ng-option>
              <ng-option [value]="9000140262">SME Group</ng-option>
              <ng-option [value]="9000140264">Sales</ng-option>
            </ng-select>
          </div>
          <div class="form-field wp-100 d-none">
            <p class="fw-bold">Client User <span class="text-red">*</span></p>
            <mat-form-field appearance="fill">
              <mat-select
                #clients
                panelClass="myPanelClass"
                placeholder="Client user"
                [disableOptionCentering]="true"
              >
                <mat-option value="0">Client user</mat-option>
                <mat-option value="1">Client user</mat-option>
                <mat-option value="2">Client user</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-field w-230">
            <p class="fw-bold">Module</p>
            <ng-select
              #module
              (focus)="module.open()"
              (valueChange)="module.close()"
              panelClass="myPanelClass"
              [disableOptionCentering]="true"
              formControlName="moduleId"
              [readonly]="true"
            >
              <ng-option
                *ngFor="let tm of ticketModules | useEnumValue"
                [value]="tm.key"
              >
                {{ tm.value }}
              </ng-option>
            </ng-select>
          </div>
        </div>
        <div class="hr-top pt-1 mt-5">
          <div class="form-field w-434">
            <p class="fw-bold">Subject <span class="text-red">*</span></p>
            <mat-form-field appearance="fill">
              <input type="text" matInput formControlName="subject" required />
            </mat-form-field>
          </div>
        </div>
        <div>
          <div class="form-field textarea">
            <p class="fw-bold">Message <span class="text-red">*</span></p>
            <mat-form-field class="wp-100" appearance="fill">
              <textarea
                matInput
                formControlName="message"
                autocomplete="off"
                required
              ></textarea>
            </mat-form-field>
          </div>
          <div class="form-field attached">
            <p class="fw-bold">Attachments</p>
            <div class="form-field attached mb-10">
              <div
                class="attachFile"
                [ngClass]="isImageSelected ? 'hidden' : ''"
              >
                <mat-icon fontSet="material-icons-outlined"
                  >cloud_upload</mat-icon
                >
                <input
                  #fileupload
                  multiple
                  type="file"
                  [accept]="acceptedFileTypes"
                  formControlName="fileName"
                  autocomplete="off"
                  maxlength="100"
                  id="file"
                  (change)="selectFiles($event)"
                  class="cursor-pointer"
                />
                <label for="file" class="cursor-pointer">
                  <strong>Choose a file</strong> or drag it here to attach a
                  file with ticket.
                </label>
              </div>
            </div>
            <span class="font-size-12">
              (Max file size - 2 MB) | Supported formats : .doc, .docx, .pdf,
              .jpg, .png, .jpeg, .txt, .xls, .xlsx, .csv</span
            >
            <div class="note-table mt-10">
              <div [ngClass]="fileList.length === 0 ? '' : 'table-border'">
                <div
                  class="clickable d-flex align-items-center justify-space-between"
                  *ngFor="let file of fileList; let i = index"
                >
                  <div>{{ file.name }}</div>
                  <div class="close mt-5">
                    <a href="javascript:void(0)" (click)="removeFile(i)">
                      <mat-icon>close</mat-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex pb-5 pt-10 hr-top pt-1 mt-5">
        <button
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          (click)="onSave()"
        >
          Submit
        </button>
        <button
          class="action-button secondary-button"
          mat-dialog-close
          disableRipple
          type="button"
          mat-button
          disableRipple
        >
          Cancel
        </button>
      </div>
    </form>
  </mat-dialog-content>
</div>
