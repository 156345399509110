import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'Subscription';
  constructor(private http: HttpClient) {}

  retrieveSubscription(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/retrieveSubscription`
    );
  }

  getClientLimit(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.routeBase}/getClientLimit`);
  }
}
