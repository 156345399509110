import { Injectable } from '@angular/core';
import { SubscriptionService } from '@app/core/services/subscription';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { GetClientLimit, RetrieveSubscription } from './subscription.action';

export class SubscriptionStateInfo {
  retrieveSubscriptionList: any;
  clientLimit: string;
}

@State<SubscriptionStateInfo>({
  name: 'subscription',
  defaults: {
    retrieveSubscriptionList: [],
    clientLimit: '0',
  },
})
@Injectable()
export class SubscriptionState {
  constructor(private subscriptionService: SubscriptionService) {}

  @Action(RetrieveSubscription)
  retrieveSubscription(
    { getState, setState }: StateContext<SubscriptionStateInfo>,
    action: RetrieveSubscription
  ) {
    return this.subscriptionService.retrieveSubscription().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          retrieveSubscriptionList: res,
        });
      })
    );
  }

  @Action(GetClientLimit)
  getClientLimit(
    { getState, setState }: StateContext<SubscriptionStateInfo>,
    action: GetClientLimit
  ) {
    return this.subscriptionService.getClientLimit().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          clientLimit: res,
        });
      })
    );
  }
}
