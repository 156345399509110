import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { tap } from 'rxjs/operators';
import {
  BillableAndNonBillableHoursByMonthChartModel,
  BillableAndNonBillableHoursByMonthModel,
  BillableHoursByQuarterChartModel,
  BillableHoursByQuarterModel,
  BillableTeammateHoursModel,
  ExpenseByStatusModel,
  IncomeByClientChartModel,
  IncomeByClientModel,
  IncomeByPaymentMethodChartModel,
  IncomeByPaymentMethodModel,
  IncomeByProjectChartModel,
  IncomeByProjectTypeChartModel,
  IncomeByProjectTypeModel,
  IncomeByTopFiveProjectModel,
  InProgressProjectByTypeModel,
  InvoiceByPaymentLateDaysChartModel,
  InvoiceRaisedHistoryChartModel,
  InvoiceRaisedHistoryModel,
  MostLeastCountModel,
  ProjectCountByStatusModel,
  ProjectDeadlineStatusModel,
  ProjectHoursByTeamamteChartModel,
  ProjectManageByUserModel,
  ProjectStatusByTimeCostAndExpenseChartModel,
  ProjectStatusByTimeCostAndExpenseModel,
  ReportDetail,
  TaskWithMeTodayModel,
  TeamAndTaskTodayModel,
  TeammatesExpectedAndActualCapacityModel,
  TeammatesHoursByClientModel,
  TeamUtilizationHoursOnClientChartModel,
  TimeDashboardSummary,
  TimeOffByTeammateModel,
  TimeOffByTeammatesChartModel,
  TimeSheetSummaryByStatusChartModel,
  TimeSheetSummaryByStatusModel,
  TopBillableTeammateHoursChartModel,
  TotalHoursByClientModel,
  UserWidgetModel,
  WeeklyScheduleModel,
} from '../../models';
import { DashboardService } from '../../services';
import {
  DeleteUserWidgetByWidgetId,
  GetAmountDueThisYearAsOnDate,
  GetAmountReceivedThisYearAsOnDate,
  GetBillableAndNonBillableHours,
  GetBillableAndNonBillableHoursByMonth,
  GetBillableAndNonBillableHoursByMonthGraphical,
  GetBillableHoursByQuarterAndByClient,
  GetBillableHoursByQuarterAndByClientGraphical,
  GetBillableTeammateByHours,
  GetBillableTeammateByHoursGraphical,
  GetExpenseByStatus,
  GetIncomeByClient,
  GetIncomeByClientGraphical,
  GetIncomeByPaymentMethods,
  GetIncomeByPaymentMethodsGraphical,
  GetIncomeByProjectGraphical,
  GetIncomeByProjectType,
  GetIncomeByProjectTypeGraphical,
  GetIncomeByTopFiveProjects,
  GetInProgressProjectsByType,
  GetInvoiceByPaymentLateDaysGraphical,
  GetInvoicedThisYearAsOnDate,
  GetInvoiceHistory,
  GetInvoiceHistoryGraphical,
  GetJobCount,
  GetListByClient,
  GetListByPaymentLateDays,
  GetListByPaymentMethod,
  GetListByStatus,
  GetListByTask,
  GetListByTeamUtilizeHoursOnTasks,
  GetListByTimeSpentByGbpOnTasks,
  GetListByTimeSpentByHoursOnTasks,
  GetListByTimeSpentOnClient,
  GetMostAndLeastCount,
  GetNumberOfOngoingProjects,
  GetOverallHoursDetail,
  GetProjectDeadlineStatus,
  GetProjectManageByUser,
  GetProjectsCountByStatus,
  GetProjectStatusByTimeCostAndExpense,
  GetProjectStatusByTimeCostAndExpenseGraphical,
  GetTaskWiseHoursDetails,
  GetTaskWithMeToday,
  GetTeamAndTaskToday,
  GetTeammatesExpectedAndActualCapacity,
  GetTeamUtilizationHoursOnClientGraphical,
  GetTeamUtilizationHoursOnClients,
  GetTimeDashboardSummary,
  GetTimeOffByTeammate,
  GetTimeOffByTeammateGraphical,
  GetTimeOffByUsers,
  GetTimeSheetSummaryByStatus,
  GetTimeSheetSummaryByStatusGraphical,
  GetTotalProjectHoursByTeammate,
  GetTotalProjectHoursByTeammateGraphical,
  GetUserWidgets,
  GetWeeklySchedule,
  RevertToDefaultDashboardSetting,
  SaveUserWidgets,
} from './dashboard.action';

export class DashboardStateInfo {
  reportDetailByClient: Array<ReportDetail>;
  reportDetailByPaymentLateDays: Array<ReportDetail>;
  reportDetailByPaymentMethod: Array<ReportDetail>;
  reportDetailByStatus: Array<ReportDetail>;
  reportDetailByTask: Array<ReportDetail>;
  reportDetailByTimeSpentOnClients: Array<ReportDetail>;
  reportDetailByTimeSpentOnTasks: Array<ReportDetail>;
  reportDetailByTeamUtilizehrsOnTasks: Array<ReportDetail>;
  reportDetailByTimeSpentByGbpOnTasks: Array<ReportDetail>;
  availableWidgets: Array<UserWidgetModel>;
  reportDetailByTeamAndTaskToday: Array<TeamAndTaskTodayModel>;
  reportDetailByTaskWithMeToday: Array<TaskWithMeTodayModel>;
  reportDetailByProjectDeadlineStatus: Array<ProjectDeadlineStatusModel>;
  reportDetailByInProgressProjectByType: Array<InProgressProjectByTypeModel>;
  reportDetailByInProjectStatusByTimeCostAndExpense: Array<ProjectStatusByTimeCostAndExpenseModel>;
  reportDetailByIncomeByTopFiveProject: Array<IncomeByTopFiveProjectModel>;
  reportDetailByIncomeByClient: Array<IncomeByClientModel>;
  reportDetailByIncomeByProjectType: Array<IncomeByProjectTypeModel>;
  reportDetailByBillableTeammateHours: Array<BillableTeammateHoursModel>;
  reportDetailByBillableAndNonBillableHoursByMonth: Array<BillableAndNonBillableHoursByMonthModel>;
  reportDetailByIncomeByPaymentMethod: Array<IncomeByPaymentMethodModel>;
  reportDetailByTeamUtilizationHoursOnClients: Array<TeammatesHoursByClientModel>;
  reportDetailByTotalHoursByClient: Array<TotalHoursByClientModel>;
  reportDetailByBillableHoursByQuarter: Array<BillableHoursByQuarterModel>;
  reportDetailByTimeSheetSummaryByStatus: Array<TimeSheetSummaryByStatusModel>;
  reportDetailByTimeOffByTeammate: Array<TimeOffByTeammateModel>;
  reportDetailByInvoiceRaisedHistory: Array<InvoiceRaisedHistoryModel>;
  reportDetailByProjectCountByStatus: Array<ProjectCountByStatusModel>;
  reportDetailByProjectManageByUser: Array<ProjectManageByUserModel>;
  reportDetailByInvoiceRaisedHistoryChart?: InvoiceRaisedHistoryChartModel;
  reportDetailByIncomeByProjectTypeChart?: IncomeByProjectTypeChartModel;
  reportDetailByIncomeByClientChart?: IncomeByClientChartModel;
  reportDetailByBillableHoursByQuarterChart?: BillableHoursByQuarterChartModel;
  reportDetailByTeamUtilizationHoursOnClientChart?: TeamUtilizationHoursOnClientChartModel;
  reportDetailByProjectHoursByTeamamteChart?: ProjectHoursByTeamamteChartModel;
  reportDetailByTopBillableTeammateHoursChart: Array<TopBillableTeammateHoursChartModel>;
  reportDetailByIncomeByProjectChart?: IncomeByProjectChartModel;
  reportDetailByIncomeByPaymentMethodChart?: IncomeByPaymentMethodChartModel;
  reportDetailByBillableAndNonBillableHoursByMonthChart?: BillableAndNonBillableHoursByMonthChartModel;
  reportDetailByNumberOfOngoingProjects: number;
  reportDetailByInvoicedThisYearAsOnDate: number;
  reportDetailByAmountReceivedThisYearAsOnDate: number;
  reportDetailByAmountDueThisYearAsOnDate: number;
  reportDetailByInvoiceByPaymentLateDaysChart?: InvoiceByPaymentLateDaysChartModel;
  reportDetailByProjectStatusByTimeCostAndExpenseChart?: ProjectStatusByTimeCostAndExpenseChartModel;
  reportDetailByTimeSheetSummaryByStatusChart: Array<TimeSheetSummaryByStatusChartModel>;
  reportDetailByTimeOffByTeammatesChart?: TimeOffByTeammatesChartModel;
  reportDetailByExpenseByStatus: Array<ExpenseByStatusModel>;
  reportDetailByWeeklySchedule: Array<WeeklyScheduleModel>;
  reportDetailByTeammatesExpectedAndActualCapacity: Array<TeammatesExpectedAndActualCapacityModel>;
  isWidgetDeleted?: boolean;
  userWidgets: Array<UserWidgetModel>;
  timeDashboardSummary?: TimeDashboardSummary;
  mostandLeastCount?: MostLeastCountModel;
  billableAndNonBillableHours?: any;
  timeOffByUsers?: any;
  overallHoursDetail?: any;
  jobCount?: any;
  taskWiseHoursDetails?: any;
  isRevertedToDefault?: boolean;
  isDashboardLocked?: boolean;
}

@State<DashboardStateInfo>({
  name: 'dashboard',
  defaults: {
    reportDetailByClient: [],
    reportDetailByPaymentLateDays: [],
    reportDetailByPaymentMethod: [],
    reportDetailByStatus: [],
    reportDetailByTask: [],
    reportDetailByTimeSpentOnClients: [],
    reportDetailByTimeSpentOnTasks: [],
    reportDetailByTeamUtilizehrsOnTasks: [],
    reportDetailByTimeSpentByGbpOnTasks: [],
    availableWidgets: [],
    userWidgets: [],
    reportDetailByTeamAndTaskToday: [],
    reportDetailByTaskWithMeToday: [],
    reportDetailByProjectDeadlineStatus: [],
    reportDetailByInProgressProjectByType: [],
    reportDetailByInProjectStatusByTimeCostAndExpense: [],
    reportDetailByIncomeByTopFiveProject: [],
    reportDetailByIncomeByClient: [],
    reportDetailByIncomeByProjectType: [],
    reportDetailByBillableTeammateHours: [],
    reportDetailByBillableAndNonBillableHoursByMonth: [],
    reportDetailByIncomeByPaymentMethod: [],
    reportDetailByTeamUtilizationHoursOnClients: [],
    reportDetailByTotalHoursByClient: [],
    reportDetailByBillableHoursByQuarter: [],
    reportDetailByTimeSheetSummaryByStatus: [],
    reportDetailByTimeOffByTeammate: [],
    reportDetailByInvoiceRaisedHistory: [],
    reportDetailByProjectCountByStatus: [],
    reportDetailByProjectManageByUser: [],
    reportDetailByTopBillableTeammateHoursChart: [],
    reportDetailByNumberOfOngoingProjects: 0,
    reportDetailByInvoicedThisYearAsOnDate: 0,
    reportDetailByAmountReceivedThisYearAsOnDate: 0,
    reportDetailByAmountDueThisYearAsOnDate: 0,
    reportDetailByTimeSheetSummaryByStatusChart: [],
    reportDetailByExpenseByStatus: [],
    reportDetailByWeeklySchedule: [],
    reportDetailByTeammatesExpectedAndActualCapacity: [],
  },
})
@Injectable()
export class DashboardState {
  constructor(private dashboardService: DashboardService) {}

  @Selector()
  static getTeammatesExpectedAndActualCapacity(state: DashboardStateInfo) {
    return state.reportDetailByTeammatesExpectedAndActualCapacity;
  }

  @Selector()
  static getClientDetailList(state: DashboardStateInfo) {
    return state.reportDetailByClient;
  }

  @Selector()
  static getPaymentLateDaysList(state: DashboardStateInfo) {
    return state.reportDetailByPaymentLateDays;
  }

  @Selector()
  static getPaymentMethodList(state: DashboardStateInfo) {
    return state.reportDetailByPaymentMethod;
  }

  @Selector()
  static getStatusList(state: DashboardStateInfo) {
    return state.reportDetailByStatus;
  }

  @Selector()
  static getTaskDetailList(state: DashboardStateInfo) {
    return state.reportDetailByTask;
  }

  @Selector()
  static getTimeSpentOnClientsList(state: DashboardStateInfo) {
    return state.reportDetailByTimeSpentOnClients;
  }

  @Selector()
  static getTimeSpentOnTaskList(state: DashboardStateInfo) {
    return state.reportDetailByTimeSpentOnTasks;
  }

  @Selector()
  static getTeamUtilizeTaskList(state: DashboardStateInfo) {
    return state.reportDetailByTeamUtilizehrsOnTasks;
  }

  @Selector()
  static getTimeSpentGbpTaskList(state: DashboardStateInfo) {
    return state.reportDetailByTimeSpentByGbpOnTasks;
  }

  @Selector()
  static getAvailableWidgets(state: DashboardStateInfo) {
    return state.availableWidgets;
  }

  @Selector()
  static getUserWidgets(state: DashboardStateInfo) {
    return state.userWidgets;
  }

  @Selector()
  static getTeamAndTaskToday(state: DashboardStateInfo) {
    return state.reportDetailByTeamAndTaskToday;
  }

  @Selector()
  static getTaskWithMeToday(state: DashboardStateInfo) {
    return state.reportDetailByTaskWithMeToday;
  }

  @Selector()
  static getProjectDeadlineStatus(state: DashboardStateInfo) {
    return state.reportDetailByProjectDeadlineStatus;
  }

  @Selector()
  static getInProgressProjectsByType(state: DashboardStateInfo) {
    return state.reportDetailByInProgressProjectByType;
  }

  @Selector()
  static getProjectStatusByTimeCostAndExpense(state: DashboardStateInfo) {
    return state.reportDetailByInProjectStatusByTimeCostAndExpense;
  }

  @Selector()
  static getIncomeByTopFiveProjects(state: DashboardStateInfo) {
    return state.reportDetailByIncomeByTopFiveProject;
  }

  @Selector()
  static getIncomeByClient(state: DashboardStateInfo) {
    return state.reportDetailByIncomeByClient;
  }

  @Selector()
  static getIncomeByProjectType(state: DashboardStateInfo) {
    return state.reportDetailByIncomeByProjectType;
  }

  @Selector()
  static getBillableTeammateByHours(state: DashboardStateInfo) {
    return state.reportDetailByBillableTeammateHours;
  }

  @Selector()
  static getBillableAndNonBillableHoursByMonth(state: DashboardStateInfo) {
    return state.reportDetailByBillableAndNonBillableHoursByMonth;
  }

  @Selector()
  static getIncomeByPaymentMethods(state: DashboardStateInfo) {
    return state.reportDetailByIncomeByPaymentMethod;
  }

  @Selector()
  static getTeamUtilizationHoursOnClients(state: DashboardStateInfo) {
    return state.reportDetailByTeamUtilizationHoursOnClients;
  }

  @Selector()
  static getTotalProjectHoursByTeammate(state: DashboardStateInfo) {
    return state.reportDetailByTotalHoursByClient;
  }

  @Selector()
  static getBillableHoursByQuarterAndByClient(state: DashboardStateInfo) {
    return state.reportDetailByBillableHoursByQuarter;
  }

  @Selector()
  static getTimeSheetSummaryByStatus(state: DashboardStateInfo) {
    return state.reportDetailByTimeSheetSummaryByStatus;
  }

  @Selector()
  static getTimeOffByTeammate(state: DashboardStateInfo) {
    return state.reportDetailByTimeOffByTeammate;
  }

  @Selector()
  static getInvoiceHistory(state: DashboardStateInfo) {
    return state.reportDetailByInvoiceRaisedHistory;
  }

  @Selector()
  static getProjectsCountByStatus(state: DashboardStateInfo) {
    return state.reportDetailByProjectCountByStatus;
  }

  @Selector()
  static getProjectManageByUser(state: DashboardStateInfo) {
    return state.reportDetailByProjectManageByUser;
  }

  @Selector()
  static getInvoiceHistoryGraphical(state: DashboardStateInfo) {
    return state.reportDetailByInvoiceRaisedHistoryChart;
  }

  @Selector()
  static getIncomeByProjectTypeGraphical(state: DashboardStateInfo) {
    return state.reportDetailByIncomeByProjectTypeChart;
  }

  @Selector()
  static getIncomeByClientGraphical(state: DashboardStateInfo) {
    return state.reportDetailByIncomeByClientChart;
  }

  @Selector()
  static getBillableHoursByQuarterAndByClientGraphical(
    state: DashboardStateInfo
  ) {
    return state.reportDetailByBillableHoursByQuarterChart;
  }

  @Selector()
  static getTeamUtilizationHoursOnClientGraphical(state: DashboardStateInfo) {
    return state.reportDetailByTeamUtilizationHoursOnClientChart;
  }

  @Selector()
  static getTotalProjectHoursByTeammateGraphical(state: DashboardStateInfo) {
    return state.reportDetailByProjectHoursByTeamamteChart;
  }

  @Selector()
  static getBillableTeammateByHoursGraphical(state: DashboardStateInfo) {
    return state.reportDetailByTopBillableTeammateHoursChart;
  }

  @Selector()
  static getIncomeByProjectGraphical(state: DashboardStateInfo) {
    return state.reportDetailByIncomeByProjectChart;
  }

  @Selector()
  static getIncomeByPaymentMethodsGraphical(state: DashboardStateInfo) {
    return state.reportDetailByIncomeByPaymentMethodChart;
  }

  @Selector()
  static getBillableAndNonBillableHoursByMonthGraphical(
    state: DashboardStateInfo
  ) {
    return state.reportDetailByBillableAndNonBillableHoursByMonthChart;
  }

  @Selector()
  static getNumberOfOngoingProjects(state: DashboardStateInfo) {
    return state.reportDetailByNumberOfOngoingProjects;
  }

  @Selector()
  static getInvoicedThisYearAsOnDate(state: DashboardStateInfo) {
    return state.reportDetailByInvoicedThisYearAsOnDate;
  }

  @Selector()
  static getAmountReceivedThisYearAsOnDate(state: DashboardStateInfo) {
    return state.reportDetailByAmountReceivedThisYearAsOnDate;
  }

  @Selector()
  static getAmountDueThisYearAsOnDate(state: DashboardStateInfo) {
    return state.reportDetailByAmountDueThisYearAsOnDate;
  }

  @Selector()
  static getInvoiceByPaymentLateDaysGraphical(state: DashboardStateInfo) {
    return state.reportDetailByInvoiceByPaymentLateDaysChart;
  }

  @Selector()
  static getProjectStatusByTimeCostAndExpenseGraphical(
    state: DashboardStateInfo
  ) {
    return state.reportDetailByProjectStatusByTimeCostAndExpenseChart;
  }

  @Selector()
  static getTimeSheetSummaryByStatusGraphical(state: DashboardStateInfo) {
    return state.reportDetailByTimeSheetSummaryByStatusChart;
  }

  @Selector()
  static getTimeOffByTeammateGraphical(state: DashboardStateInfo) {
    return state.reportDetailByTimeOffByTeammatesChart;
  }

  @Selector()
  static getExpenseByStatus(state: DashboardStateInfo) {
    return state.reportDetailByExpenseByStatus;
  }

  @Selector()
  static getWeeklySchedule(state: DashboardStateInfo) {
    return state.reportDetailByWeeklySchedule;
  }
  // 2.0 Selector start

  @Selector()
  static getTimeDashboardSummary(state: DashboardStateInfo) {
    return state.timeDashboardSummary;
  }

  @Selector()
  static getMostandLeastCount(state: DashboardStateInfo) {
    return state.mostandLeastCount;
  }

  @Selector()
  static isDashboardLocked(state: DashboardStateInfo) {
    return state.isDashboardLocked ?? false;
  }

  // 2.0 Selector End

  @Action(GetTeammatesExpectedAndActualCapacity)
  getTeammatesExpectedAndActualCapacity(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetTeammatesExpectedAndActualCapacity
  ) {
    return this.dashboardService
      .getTeammatesExpectedAndActualCapacity(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByTeammatesExpectedAndActualCapacity: res,
          });
        })
      );
  }

  @Action(GetListByClient)
  getListByClient(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetListByClient
  ) {
    return this.dashboardService
      .getListByClient(action.filter, action.startDate, action.endDate)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByClient: res,
          });
        })
      );
  }

  @Action(GetListByPaymentLateDays)
  getListByPaymentLateDays(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetListByPaymentLateDays
  ) {
    return this.dashboardService
      .getByPaymentLateDays(action.filter, action.startDate, action.endDate)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByPaymentLateDays: res,
          });
        })
      );
  }

  @Action(GetListByPaymentMethod)
  getListByPaymentMethod(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetListByPaymentMethod
  ) {
    return this.dashboardService
      .getByPaymentMethod(action.filter, action.startDate, action.endDate)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByPaymentMethod: res,
          });
        })
      );
  }

  @Action(GetListByStatus)
  getListByStatus(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetListByStatus
  ) {
    return this.dashboardService
      .getByStatus(action.filter, action.startDate, action.endDate)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByStatus: res,
          });
        })
      );
  }

  @Action(GetListByTask)
  getListByTask(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetListByTask
  ) {
    return this.dashboardService
      .getByTask(action.filter, action.startDate, action.endDate)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByTask: res,
          });
        })
      );
  }

  @Action(GetListByTimeSpentOnClient)
  getListByTimeSpentOnClient(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetListByTimeSpentOnClient
  ) {
    return this.dashboardService
      .getByTimeSpentOnClients(action.filter, action.startDate, action.endDate)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByTimeSpentOnClients: res,
          });
        })
      );
  }

  @Action(GetListByTimeSpentByHoursOnTasks)
  getListByTimeSpentByHoursOnTasks(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetListByTimeSpentByHoursOnTasks
  ) {
    return this.dashboardService
      .getByRateOfTimeSpentByHoursOnTasks(
        action.filter,
        action.startDate,
        action.endDate
      )
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByTimeSpentOnTasks: res,
          });
        })
      );
  }

  @Action(GetListByTeamUtilizeHoursOnTasks)
  getListByTeamUtilizeHoursOnTasks(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetListByTeamUtilizeHoursOnTasks
  ) {
    return this.dashboardService
      .getByTeamUtilizeHoursOnTasks(
        action.filter,
        action.startDate,
        action.endDate
      )
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByTeamUtilizehrsOnTasks: res,
          });
        })
      );
  }

  @Action(GetListByTimeSpentByGbpOnTasks)
  getListByTimeSpentByGbpOnTasks(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetListByTimeSpentByGbpOnTasks
  ) {
    return this.dashboardService
      .getByRateOfTimeSpentByGbpOnTasks(
        action.filter,
        action.startDate,
        action.endDate
      )
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByTimeSpentByGbpOnTasks: res,
          });
        })
      );
  }

  // New Dashboard Changes

  @Action(GetTeamAndTaskToday)
  getTeamAndTaskToday(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetTeamAndTaskToday
  ) {
    return this.dashboardService.getTeamAndTaskToday(action.selectedDate).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          reportDetailByTeamAndTaskToday: res,
        });
      })
    );
  }

  @Action(GetTaskWithMeToday)
  getTaskWithMeToday(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetTaskWithMeToday
  ) {
    return this.dashboardService.getTaskWithMeToday(action.selectedDate).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          reportDetailByTaskWithMeToday: res,
        });
      })
    );
  }

  @Action(GetProjectDeadlineStatus)
  getProjectDeadlineStatus(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetProjectDeadlineStatus
  ) {
    return this.dashboardService
      .getProjectDeadlineStatus(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByProjectDeadlineStatus: res,
          });
        })
      );
  }

  @Action(GetInProgressProjectsByType)
  getInProgressProjectsByType(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetInProgressProjectsByType
  ) {
    return this.dashboardService
      .getInProgressProjectsByType(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByInProgressProjectByType: res,
          });
        })
      );
  }

  @Action(GetProjectStatusByTimeCostAndExpense)
  getProjectStatusByTimeCostAndExpense(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetProjectStatusByTimeCostAndExpense
  ) {
    return this.dashboardService
      .getProjectStatusByTimeCostAndExpense(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByInProjectStatusByTimeCostAndExpense: res,
          });
        })
      );
  }

  @Action(GetIncomeByTopFiveProjects)
  getIncomeByTopFiveProjects(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetIncomeByTopFiveProjects
  ) {
    return this.dashboardService
      .getIncomeByTopFiveProjects(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByIncomeByTopFiveProject: res,
          });
        })
      );
  }

  @Action(GetIncomeByClient)
  getIncomeByClient(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetIncomeByClient
  ) {
    return this.dashboardService
      .getIncomeByClient(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByIncomeByClient: res,
          });
        })
      );
  }

  @Action(GetIncomeByProjectType)
  getIncomeByProjectType(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetIncomeByProjectType
  ) {
    return this.dashboardService
      .getIncomeByProjectType(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByIncomeByProjectType: res,
          });
        })
      );
  }

  @Action(GetBillableTeammateByHours)
  getBillableTeammateByHours(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetBillableTeammateByHours
  ) {
    return this.dashboardService
      .getBillableTeammateByHours(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByBillableTeammateHours: res,
          });
        })
      );
  }

  @Action(GetBillableAndNonBillableHoursByMonth)
  getBillableAndNonBillableHoursByMonth(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetBillableAndNonBillableHoursByMonth
  ) {
    return this.dashboardService
      .getBillableAndNonBillableHoursByMonth(action.selectedYear)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByBillableAndNonBillableHoursByMonth: res,
          });
        })
      );
  }

  @Action(GetIncomeByPaymentMethods)
  getIncomeByPaymentMethods(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetIncomeByPaymentMethods
  ) {
    return this.dashboardService
      .getIncomeByPaymentMethods(action.selectedYear)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByIncomeByPaymentMethod: res,
          });
        })
      );
  }

  @Action(GetTeamUtilizationHoursOnClients)
  getTeamUtilizationHoursOnClients(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetTeamUtilizationHoursOnClients
  ) {
    return this.dashboardService
      .getTeamUtilizationHoursOnClients(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByTeamUtilizationHoursOnClients: res,
          });
        })
      );
  }

  @Action(GetTotalProjectHoursByTeammate)
  getTotalProjectHoursByTeammate(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetTotalProjectHoursByTeammate
  ) {
    return this.dashboardService
      .getTotalProjectHoursByTeammate(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByTotalHoursByClient: res,
          });
        })
      );
  }

  @Action(GetBillableHoursByQuarterAndByClient)
  getBillableHoursByQuarterAndByClient(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetBillableHoursByQuarterAndByClient
  ) {
    return this.dashboardService
      .getBillableHoursByQuarterAndByClient(action.selectedYear)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByBillableHoursByQuarter: res,
          });
        })
      );
  }

  @Action(GetTimeSheetSummaryByStatus)
  getTimeSheetSummaryByStatus(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetTimeSheetSummaryByStatus
  ) {
    return this.dashboardService
      .getTimeSheetSummaryByStatus(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByTimeSheetSummaryByStatus: res,
          });
        })
      );
  }

  @Action(GetTimeOffByTeammate)
  getTimeOffByTeammate(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetTimeOffByTeammate
  ) {
    return this.dashboardService
      .getTimeOffByTeammate(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByTimeOffByTeammate: res,
          });
        })
      );
  }

  @Action(GetInvoiceHistory)
  getInvoiceHistory(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetInvoiceHistory
  ) {
    return this.dashboardService
      .getInvoiceHistory(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByInvoiceRaisedHistory: res,
          });
        })
      );
  }

  @Action(GetProjectsCountByStatus)
  getProjectsCountByStatus(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetProjectsCountByStatus
  ) {
    return this.dashboardService
      .getProjectsCountByStatus(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByProjectCountByStatus: res,
          });
        })
      );
  }

  @Action(GetProjectManageByUser)
  getProjectManageByUser(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetProjectManageByUser
  ) {
    return this.dashboardService
      .getProjectManageByUser(action.selectedDate)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByProjectManageByUser: res,
          });
        })
      );
  }

  @Action(GetInvoiceHistoryGraphical)
  getInvoiceHistoryGraphical(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetInvoiceHistoryGraphical
  ) {
    return this.dashboardService
      .getInvoiceHistoryGraphical(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByInvoiceRaisedHistoryChart: res,
          });
        })
      );
  }

  @Action(GetIncomeByProjectTypeGraphical)
  getIncomeByProjectTypeGraphical(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetIncomeByProjectTypeGraphical
  ) {
    return this.dashboardService
      .getIncomeByProjectTypeGraphical(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByIncomeByProjectTypeChart: res,
          });
        })
      );
  }

  @Action(GetIncomeByClientGraphical)
  getIncomeByClientGraphical(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetIncomeByClientGraphical
  ) {
    return this.dashboardService
      .getIncomeByClientGraphical(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByIncomeByClientChart: res,
          });
        })
      );
  }

  @Action(GetBillableHoursByQuarterAndByClientGraphical)
  getBillableHoursByQuarterAndByClientGraphical(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetBillableHoursByQuarterAndByClientGraphical
  ) {
    return this.dashboardService
      .getBillableHoursByQuarterAndByClientGraphical(action.selectedYear)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByBillableHoursByQuarterChart: res,
          });
        })
      );
  }

  @Action(GetTeamUtilizationHoursOnClientGraphical)
  getTeamUtilizationHoursOnClientGraphical(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetTeamUtilizationHoursOnClientGraphical
  ) {
    return this.dashboardService
      .getTeamUtilizationHoursOnClientGraphical(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByTeamUtilizationHoursOnClientChart: res,
          });
        })
      );
  }

  @Action(GetTotalProjectHoursByTeammateGraphical)
  getTotalProjectHoursByTeammateGraphical(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetTotalProjectHoursByTeammateGraphical
  ) {
    return this.dashboardService
      .getTotalProjectHoursByTeammateGraphical(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByProjectHoursByTeamamteChart: res,
          });
        })
      );
  }

  @Action(GetBillableTeammateByHoursGraphical)
  getBillableTeammateByHoursGraphical(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetBillableTeammateByHoursGraphical
  ) {
    return this.dashboardService
      .getBillableTeammateByHoursGraphical(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByTopBillableTeammateHoursChart: res,
          });
        })
      );
  }

  @Action(GetIncomeByProjectGraphical)
  getIncomeByProjectGraphical(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetIncomeByProjectGraphical
  ) {
    return this.dashboardService
      .getIncomeByProjectGraphical(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByIncomeByProjectChart: res,
          });
        })
      );
  }

  @Action(GetIncomeByPaymentMethodsGraphical)
  getIncomeByPaymentMethodsGraphical(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetIncomeByPaymentMethodsGraphical
  ) {
    return this.dashboardService
      .getIncomeByPaymentMethodsGraphical(action.selectedYear)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByIncomeByPaymentMethodChart: res,
          });
        })
      );
  }

  @Action(GetBillableAndNonBillableHoursByMonthGraphical)
  getBillableAndNonBillableHoursByMonthGraphical(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetBillableAndNonBillableHoursByMonthGraphical
  ) {
    return this.dashboardService
      .getBillableAndNonBillableHoursByMonthGraphical(action.selectedYear)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByBillableAndNonBillableHoursByMonthChart: res,
          });
        })
      );
  }

  @Action(GetNumberOfOngoingProjects)
  getNumberOfOngoingProjects(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetNumberOfOngoingProjects
  ) {
    return this.dashboardService
      .getNumberOfOngoingProjects(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByNumberOfOngoingProjects: res,
          });
        })
      );
  }

  @Action(GetInvoicedThisYearAsOnDate)
  getInvoicedThisYearAsOnDate(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetInvoicedThisYearAsOnDate
  ) {
    return this.dashboardService
      .getInvoicedThisYearAsOnDate(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByInvoicedThisYearAsOnDate: res,
          });
        })
      );
  }

  @Action(GetAmountReceivedThisYearAsOnDate)
  getAmountReceivedThisYearAsOnDate(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetAmountReceivedThisYearAsOnDate
  ) {
    return this.dashboardService
      .getAmountReceivedThisYearAsOnDate(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByAmountReceivedThisYearAsOnDate: res,
          });
        })
      );
  }

  @Action(GetAmountDueThisYearAsOnDate)
  getAmountDueThisYearAsOnDate(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetAmountDueThisYearAsOnDate
  ) {
    return this.dashboardService
      .getAmountDueThisYearAsOnDate(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByAmountDueThisYearAsOnDate: res,
          });
        })
      );
  }

  @Action(GetInvoiceByPaymentLateDaysGraphical)
  getInvoiceByPaymentLateDaysGraphical(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetInvoiceByPaymentLateDaysGraphical
  ) {
    return this.dashboardService
      .getInvoiceByPaymentLateDaysGraphical(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByInvoiceByPaymentLateDaysChart: res,
          });
        })
      );
  }

  @Action(GetProjectStatusByTimeCostAndExpenseGraphical)
  getProjectStatusByTimeCostAndExpenseGraphical(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetProjectStatusByTimeCostAndExpenseGraphical
  ) {
    return this.dashboardService
      .getProjectStatusByTimeCostAndExpenseGraphical(
        action.dashboardRequestModel
      )
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByProjectStatusByTimeCostAndExpenseChart: res,
          });
        })
      );
  }

  @Action(GetTimeSheetSummaryByStatusGraphical)
  getTimeSheetSummaryByStatusGraphical(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetTimeSheetSummaryByStatusGraphical
  ) {
    return this.dashboardService
      .getTimeSheetSummaryByStatusGraphical(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByTimeSheetSummaryByStatusChart: res,
          });
        })
      );
  }

  @Action(GetTimeOffByTeammateGraphical)
  getTimeOffByTeammateGraphical(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetTimeOffByTeammateGraphical
  ) {
    return this.dashboardService
      .getTimeOffByTeammateGraphical(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            reportDetailByTimeOffByTeammatesChart: res,
          });
        })
      );
  }

  @Action(GetExpenseByStatus)
  getExpenseByStatus(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetExpenseByStatus
  ) {
    return this.dashboardService.getExpenseByStatus(action.status).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          reportDetailByExpenseByStatus: res,
        });
      })
    );
  }

  @Action(GetWeeklySchedule)
  gettWeeklySchedule(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetWeeklySchedule
  ) {
    return this.dashboardService.getWeeklySchedule().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          reportDetailByWeeklySchedule: res,
        });
      })
    );
  }

  // 2.0
  @Action(GetUserWidgets)
  getUserWidgets(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetUserWidgets
  ) {
    return this.dashboardService.getUserWidgets().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          userWidgets: res.data,
          isDashboardLocked: res.isDashboardLocked,
        });
      })
    );
  }

  @Action(GetTimeDashboardSummary)
  getTimeDashboardSummary(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetTimeDashboardSummary
  ) {
    return this.dashboardService
      .getTimeDashboardSummary(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            timeDashboardSummary: res,
          });
        })
      );
  }

  @Action(GetMostAndLeastCount)
  getMostAndLeastCount(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetMostAndLeastCount
  ) {
    return this.dashboardService
      .getMostAndLeastCount(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            mostandLeastCount: res,
          });
        })
      );
  }

  @Action(SaveUserWidgets)
  saveUserWidgets(
    { getState, patchState }: StateContext<DashboardStateInfo>,
    action: SaveUserWidgets
  ) {
    return this.dashboardService.saveUserWidgets(action.userWidgetModel);
  }

  @Action(DeleteUserWidgetByWidgetId)
  deleteUserWidgetByWidgetId(
    { getState, patchState }: StateContext<DashboardStateInfo>,
    action: DeleteUserWidgetByWidgetId
  ) {
    return this.dashboardService
      .deleteUserWidgetByWidgetId(action.widgetId)
      .pipe(
        tap((res) => {
          patchState({
            isWidgetDeleted: res,
          });
        })
      );
  }

  @Action(GetTaskWiseHoursDetails)
  getTaskWiseHoursDetails(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetTaskWiseHoursDetails
  ) {
    return this.dashboardService
      .getTaskWiseHoursDetails(action.dashboardRequestModel, action.taskId)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            taskWiseHoursDetails: res,
          });
        })
      );
  }

  @Action(GetBillableAndNonBillableHours)
  getBillableAndNonBillableHours(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetBillableAndNonBillableHours
  ) {
    return this.dashboardService
      .getBillableAndNonBillableHours(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            billableAndNonBillableHours: res,
          });
        })
      );
  }

  @Action(GetTimeOffByUsers)
  getTimeOffByUsers(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetTimeOffByUsers
  ) {
    return this.dashboardService
      .getTimeOffByUsers(action.dashboardRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            timeOffByUsers: res,
          });
        })
      );
  }

  @Action(GetOverallHoursDetail)
  getOverallHoursDetail(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetOverallHoursDetail
  ) {
    return this.dashboardService
      .getOverallHoursDetail(action.dashboardRequestModel, action.taskId)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            overallHoursDetail: res,
          });
        })
      );
  }

  @Action(GetJobCount)
  getJobCount(
    { getState, setState }: StateContext<DashboardStateInfo>,
    action: GetJobCount
  ) {
    return this.dashboardService.getJobCount(action.dashboardRequestModel).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          jobCount: res,
        });
      })
    );
  }

  @Action(RevertToDefaultDashboardSetting)
  revertToDefaultDashboardSetting(
    { patchState }: StateContext<DashboardStateInfo>,
    action: RevertToDefaultDashboardSetting
  ) {
    return this.dashboardService.revertToDefaultDashboardSetting().pipe(
      tap((res) => {
        patchState({
          isRevertedToDefault: res,
        });
      })
    );
  }
}
