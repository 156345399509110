export enum NotificationHeader {
  deleteConfirmation = 'Confirm Delete',
  archiveConfirmation = 'Confirm Archive',
  restoreConfirmation = 'Confirm Restore',
  endConfirmation = 'End Recurring Schedule',
  success = 'Success!',
  error = 'Error!',
  info = 'Info!',
  sendEmail = 'Send Email',
  navigateMeToGmail = 'Navigate Me to Gmail',
  reauthenticateYourAccount = 'Reauthenticate Your Account',
  withdrawTimeSheet = 'Withdraw Timesheet Approval',
  withdrawExpense = 'Send Expense for Approval',
  sendReminderToSubmit = 'Send Reminder to Submit',
  resubmitForApproval = 'Resubmit for Approval',
  withdrawApprovalConfirmation = 'Withdraw Approval Confirmation',
  deleteInvoiceConfirmation = 'Delete Invoice Confirmation',
  editInvoiceConfirmation = 'Edit Invoice Confirmation',
  approvalConfirmation = 'Approval Confirmation',
  rejectionConfirmation = 'Rejection Confirmation',
  submitForApprovalConfirmation = 'Submit for Approval Confirmation',
  resubmitForApprovalConfirmation = 'Resubmit for Approval Confirmation',
  submitTimesheet = 'Timesheet Submission Confirmation',
  submitExpenseConfirmation = 'Expense Submission Confirmation',
  unMarkAsDefault = 'Confirm Unmark Tax as Default',
  markAsDefault = 'Confirm Mark Tax as Default',
  removeDemoData = 'Remove Demo Data',
  addDemoData = 'Add Demo Data',
  deleteYourAccount = 'Delete Your Account',
  navigateMeToOutlook = 'Navigate Me to Outlook',
  navigateMeToStripe = 'Navigate Me to Stripe Payment Gateway',
  stopTimerConfirmation = 'Stop Timer',
  editColumnConfirmation = 'Confirm Editing Columns',
  deleteInvoiceRecordPayment = 'Delete Payment',
  endJobRecurringConfirmation = 'End this recurring',
  sendReminder = 'Send Reminder',
  rejectTimesheet = 'Reject Timesheet',
  sendNote = 'Send Note',
  withdrawApproval = 'Withdraw Approval',
  withdrawRejection = 'Withdraw Rejection',
  saveFeesType = 'Save Fee Type',
  importHeaderText = 'Confirmation',
  applyMinimumChargeableTimeConfirmation = 'Apply Minimum Chargeable Time',
}

export enum NotificationDetails {
  deleteDetailText = 'Are you sure you want to delete <strong> ',
  stopTimerDetailText = 'Timer is already in progress for another job. Are you sure you want to stop the timer? ',
  questionMark = ' </strong> ?',
  archiveDetailText = 'Are you sure you want to archive <strong> ',
  endDetailText = 'Sure, you want to end this recurring schedule?',
  restoreDetailText = 'Are you sure you want to restore <strong> ',
  deleteAllDetailText = 'Please confirm you want to delete these things. Deleting cannot be undone.',
  archiveAllDetailText = 'Please confirm you want to archive these things.',
  restoreAllDetailText = 'Please confirm you want to restore these things.',
  resubmitForApprovalDetailText = 'Are you sure you want to resubmit the expense for approval?',
  withdrawTimeSheetDetailText = 'Are you sure you want to withdraw the timesheet approval?',
  approveDetailText = 'Are you sure to approve the timehseet?',
  withdrawApprovalDetailText = 'Withdraw approval',
  deleteInvoiceDetailText = 'Are you sure you want to delete the invoice?',
  withdrawApprovalUnlockDetailText = 'Are you sure to withdraw approval and unlock the timehseet?',
  withdrawApprovalExpenseDetailText = 'Are you sure to withdraw approval?',
  approveExpenseDetailText = 'Are you sure to approve the expense?',
  rejectExpenseDetailText = 'Are you sure to reject the expense?',
  submitApproveDetailText = 'Are you sure to submit the timehseet for approval?',
  resubmitApproveDetailText = 'Are you sure to resubmit the timehseet for approval?',
  resubmitApproveExpenseDetailText = 'Are you sure to resubmit the expense for approval?',
  sendReminderOnSubmitDetailText = 'Are you sure to send the reminder for submitting the timesheet for approval?',
  sendReminderOnSubmitExpenseDetailText = 'Are you sure to send the reminder for submitting the expense for approval?',
  submitTimesheetDetailText = 'Are you sure to submit the timesheet(s) for approval?',
  submitExpenseDetailText = 'Are you sure to submit the expense(s) for approval?',
  unMarkAsDefaultDetailText = 'Are you sure you want to unmark tax as default <strong> ',
  markAsDefaultDetailText = 'Are you sure you want to mark tax as default <strong> ',
  navigateMeToGmailText = 'Are you sure you want to navigate to your Gmail account.',
  reauthenticateYourAccountText = 'Are you sure you want to reauthenticate <br/> your account.',
  removeDemoDataConfirmation = 'Are you sure to remove demo data?',
  addDemoDataConfirmation = 'Are you sure to add demo data?',
  deleteYourAccountText = 'Are you sure you want to delete your stripe account?',
  navigateMeToOutlookText = 'Are you sure you want to navigate to your Outlook account.',
  reauthenticateYourAccountFromInvoiceText = 'Your account is not authenticated, <br/> do you want to reauthenticate your account',
  navigateMeToStripeText = 'Are you sure you want to navigate to stripe.',
  timelogDeleteDetailText = 'Are you sure you want to delete selected timelog? ',
  deleteRecordPaymentText = 'Are you sure you want to delete this payment?',
  endRecurringJobDetailText = 'Sure, you want to stop generating the jobs for this schedule?',
  approveTimesheet = 'Once approved, time will be locked and no one will be able to edit it anymore. ',
  deleteSelectedDetailText = 'Are you sure you want to delete selected record(s)? ',
  saveFeesType = 'Are you sure you want to save fees type? ',
  importBodyText = 'Sure, you want to ignore and continue.',
  changeClientMessage = 'If you are changing the client, all the jobs and expenses that are in the details grid will get reset.',
  minimumChargeableTimeMessage = 'The minimum chargeable time is set to #time min. System rounds up the time to it.',
}

export enum NotificationTextMessage {
  savedTimelog = 'Timesheet saved successfully',
  clientUpdated = 'Client details updated successfully.',
  companyUpdated = 'Company details updated successfully.',
  emailSent = 'Email sent successfully.',
  estimateConvertedInvoice = 'Estimate converted to invoice successfully.',
  resendInvoice = 'Resend invoice successfully',
  paymentsAndRemindersDeletion = 'All payments and reminders deleted successfully. You can now edit the invoice',
  invoiceDeleted = 'Invoice deleted successfully.',
  paymentAddedSucessfully = 'Payment due term added successfully',
  estimateUpdated = 'Estimate settings updated successfully.',
  invoiceUpdated = 'Invoice settings updated successfully.',
  selectOneOrMoreRecordToWithdraw = 'Select one or more records to withdraw',
  selectOneOrMoreRecordToApprove = 'Select one or more records to approve',
  selectOneOrMoreRecordToSendEmail = 'Select one or more records to send email',
  selectOneOrMoreRecordToReminder = 'Select one or more records to send reminder',
  selectOneOrMoreRecordToSubmit = 'Select one or more records to submit',
  timesheetApproved = 'Timesheet approved sucessfully',
  selectOneOrMoreRecordToDelete = 'Select one or more records to delete',
  selectOneOrMoreRecordToExport = 'Select one or more records to export',
  selectOneOrMoreRecordToResend = 'Select one or more records to resend',
  selectOneOrMoreRecordToMarkAsPaid = 'Select one or more records to mark as paid',
  selectOneOrMoreRecordToMarkAsSent = 'Select one or more records to mark as sent',
  submitForApprovalTimelog = 'Timesheet successfully submitted for approval',
  submitForApprovalExpense = 'Expense successfully submitted for approval',
  resubmitForApprovalTimelog = 'Timesheet successfully resubmitted for approval',
  resubmitForApprovalExpense = 'Expense successfully resubmitted for approval',
  withdrawTimelogApproval = 'Approval withdrawn and timesheet unlocked successfully',
  withdrawExpenseApproval = 'Approval withdrawn successfully',
  sentReminder = 'Reminder sent successfully',
  emailSendSucessfully = 'Email send successfully',
  approvedTimelog = 'Timesheet approved successfully',
  approvedExpense = 'Expense approved successfully',
  recordDeletedSuccessfully = 'Record deleted successfully',
  recordEditedSuccessfully = 'Record edited successfully',
  recordArchivedSuccessfully = 'Record archived successfully',
  recordRestoredSuccessfully = 'Record restored successfully',
  recordAddedSuccessfully = 'Record added successfully',
  recordCopySuccessfully = 'Record copied successfully',
  markAsDefaultSuccessfully = 'Record mark as default successfully',
  unmarkAsDefaultSuccessfully = 'Record unmark as default successfully',
  markAsPaidSuccessfully = 'Record mark as paid successfully',
  markAsSentSuccessfully = 'Record mark as sent successfully',
  scheduleEndedSuccessfully = 'Schedule ended successfully',
  schedulePausedSuccessfully = 'Schedule paused successfully',
  scheduleResumedSuccessfully = 'Schedule resumed successfully',
  successMessage = 'The operation completed successfully!',
  errorMessage = 'Something went wrong. Please try again later.',
  receiptSent = 'Payment receipt sent successfully.',
  mailDeleteSucessfully = 'Mail deleted successfully',
  selectModuletoImportData = 'Select module and file to import',
  selectModule = 'Select module to download template',
  fileSizeExceeds = 'File should be less than 10 MB',
  fileSize5mb = 'File should be less than 5 MB',
  fileSize2mb = 'File should be less than 2 MB',
  fileJpgPng = 'File should be .png or .jpg extension',
  filenameWithSpecialCharacter = 'File name should not contain special character',
  fileFormateNotSelected = 'Please select file format',
  stripeAccountDeleteSucessfully = 'Stripe account deleted successfully',
  selectModuletoExportData = 'Select one or more module to export',
  wrongCapacityFormat = 'Please check capacity fomate(0:00)',
  seperateEmailMessage = 'Please separate multiple email addresses using the comma character',
  invoiceIdExist = 'Invoice ID is already exist.',
  recurringInvoiceIdExist = 'Recurring Invoice ID is already exist.',
  estimateIdExist = 'Estimate ID is already exist.',
  taskExist = 'Task is already exist.',
  companyNameExist = 'Company name is already exist.',
  individualNameExist = 'Name is already exist.',
  clientExist = 'Client is already exist.',
  expenseTypeExist = 'Expense type is already exist.',
  taxExist = 'Tax is already exist.',
  userExist = 'User is already exist.',
  timelogAlreadyApprovedText = 'This timelog is approved.Contact your administrator to request modifications.',
  timelogAlreadyRejectedText = 'This timelog is rejected.Contact your administrator to request modifications.',
  noRecordFoundText = 'No record found.',
  demoDataAddedMessage = 'Demo data added successfully',
  demoDataRemovedMessage = 'Demo data removed successfully',
  alreadyArchive = 'Selected record has already archived record',
  alreadyRestore = 'Selected record has already active record',
  alreadyInactive = 'Selected record has already inactive record',
  mandatoryField = 'Mandatory column missing',
  emailSentMessage = 'Your request sent successfully',
  canNotDelete = 'You do not have permission to delete selected records',
  canNotArchive = 'You do not have permission to archive selected records',
  validationMessage = 'Please fill all the mandatory fields.',
  noDataFoundDateRange = 'No data found for this date range',
  invalidEmailIds = 'Please enter valid email id(s).',
  timeAlreadyStartedMessage = 'Timer is already in progress for another task',
  linkCopySuccessfully = 'Link copied successfully',
  timeSheetRejected = 'Timesheet rejected sucessfully',
  approveTimeLogWithdrawn = 'Approved timelog has been withdrawn',
  rejectedTimeLogWithdrawn = 'Rejected timelog has been withdrawn',
  StartTimeCannotGreaterThanEndtime = 'Start time cannot be greater than end time',
  nonBillableMessage = 'All relevant time associated with this job will also be non-billable',
  billableMessage = 'All relevant time associated with this job will also be billable',
  fileUploaded = 'File uploaded successfully',
  successList = 'No records are updated',
  moveToSuccessList = 'Record moved to success list',
  deleteTimesheetMessage = "You can delete this time using this icon because it hasn't been approved yet.",
  approvedTimesheetMessage = 'You are no longer able to edit this as it has been approved. Please get in touch with the admin or manager to withdraw approval if you want to change this.',
  rejectedTimesheetMessage = 'You are no longer able to edit this as it has been rejected. Please get in touch with the admin or manager to withdraw rejection if you want to change this.',
  jobCompletedMessage = 'You are no longer able to edit this as the job has been marked as completed. Please get in touch with admin or manager to change the status of Job.',
  timelogInvoicedMessage = 'You are no longer able to edit this as the timelog has been already invoiced. Please get in touch with admin or manager to delete invoice if you want to change this.',
  permissionChangeAndNotSave = 'Please save your permission changes to edit user permissions.',
  assignUserMessage = 'Please assign the user before you start the timer.',
  restrictClientActionOnRunningTimer = 'There is a timer running on this client. To proceed with this action, please stop the timer running on it.',
  restrictTaskActionOnRunningTimer = 'There is a timer running on this task. To proceed with this action, please stop the timer running on it.',
  restrictJobActionOnRunningTimer = 'There is a timer running on this job. To proceed with this action, please stop the timer running on it.',
  selectJobToStartTimer = 'Select job to start timer',
  discountExceeds = 'Discount cannot be more than sub total',
  dashboardLockedMessage = 'Dashboard locked successfully',
  dashboardUnlockedMessage = 'Dashboard unlocked successfully',
}

export enum CommonNotificationText {
  NoRecordsFound = 'No records found',
  NoRecordsFoundForFilter = 'No records found for the given search/filter values',
  Active = 'Active',
  Archived = 'Archived',
  terminateProcessHeaderText = 'Terminate Process',
  terminateProcessBodyText = 'Sure, you want to terminate process.',
  ViewOnlyAccessMessage = 'You have view only access for manage section. Please contact your manager for edit access.',
  invoiceViewOnlyMessage = 'As there is payment(s) recorded on this invoice, you are not allowed to edit the "Basic information"',
  recurringEnd = 'As the schedule has ended for this invoice, you are not allowed to edit the invoice',
}

export enum TimeNoteDetailText {
  sendRejectionNoteToUser = 'Send rejection note to user',
  note = 'Note',
}
